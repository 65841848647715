import moment from "moment";
import { toast } from "react-toastify";
import { GraphType, TimeRange } from "../enums";

export function enumToObject<A, B>(
  _enum: A,
  converter: (value: string) => B
): B[] {
  return Object.entries(_enum as any)
    .filter((e) => !isNaN(e[0] as any))
    .map((e) => converter(e[1] as any));
}

export function capitalizeFirstLetter(value: string) {
  const rev = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  return rev.replace(/_/g, " ");
}

export function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function showSuccessToast(message: string) {
  toast.success(message);
}

export function showFailureToast(message: string) {
  toast.error(message);
}

export const timeRanges = [
  { value: TimeRange.LAST_30_MINUTES, label: "Last 30 Minutes" },
  { value: TimeRange.LAST_HOUR, label: "Last Hour" },
  { value: TimeRange.TODAY, label: "Today" },
  { value: TimeRange.YESTERDAY, label: "Yesterday" },
  { value: TimeRange.LAST_7_DAYS, label: "Last 7 Days" },
  { value: TimeRange.LAST_30_DAYS, label: "Last 30 Days" },
  { value: TimeRange.LAST_3_MONTHS, label: "Last 3 Months" },
  { value: TimeRange.CUSTOM, label: "Custom" },
];

export const graphTypes = [
  { value: GraphType.AREA, label: "Area" },
  { value: GraphType.BAR, label: "Bar" },
  { value: GraphType.LINE, label: "Line" },
  { value: GraphType.PIE, label: "Pie" },
  { value: GraphType.SCATTER, label: "Scatter" },
];

export const frequencies = [
  { label: "30 Seconds", value: "0.5" },
  { label: "1 Minute", value: "1" },
  { label: "5 Minutes", value: "5" },
  { label: "10 Minutes", value: "10" },
  { label: "30 Minutes", value: "30" },
  { label: "1 Hour", value: "60" },
  { label: "2 Hours", value: "120" },
  { label: "6 Hours", value: "360" },
  { label: "8 Hours", value: "480" },
  { label: "12 Hours", value: "720" },
  { label: "24 Hours", value: "1440" },
];

export const getStartAndEndTimeForTrendsChart = (
  range: TimeRange
): { startTime: string; endTime: string } => {
  const time = moment();
  let startTime: string = "";
  let endTime: string = time.clone().toISOString(true);

  switch (range) {
    case TimeRange.LAST_30_MINUTES:
      startTime = time.clone().subtract("30", "minute").toISOString(true);
      break;
    case TimeRange.LAST_HOUR:
      startTime = time.clone().subtract("1", "hour").toISOString(true);
      break;
    case TimeRange.TODAY:
      startTime = time.clone().startOf("day").toISOString(true);
      break;
    case TimeRange.YESTERDAY:
      startTime = time
        .clone()
        .subtract("1", "day")
        .startOf("day")
        .toISOString(true);
      endTime = time
        .clone()
        .subtract("1", "day")
        .endOf("day")
        .toISOString(true);
      break;
    case TimeRange.LAST_7_DAYS:
      startTime = time.clone().subtract("7", "day").toISOString(true);
      break;
    case TimeRange.LAST_30_DAYS:
      startTime = time.clone().subtract("30", "day").toISOString(true);
      break;
    case TimeRange.LAST_3_MONTHS:
      startTime = time.clone().subtract("3", "month").toISOString(true);
      break;
    default:
      startTime = time.clone().toISOString(true);
  }

  return { startTime, endTime };
};
