import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLazyTelemetryViewQuery } from "../../../redux/api/trend-api/trend-api";
import { useDeleteWidgetMutation } from "../../../redux/api/widget-api/widget-api";
import { useAppSelector } from "../../../redux/store/store";
import iconDropDown from "../../assets/images/arrow_drop_down.svg";
import heat from "../../assets/images/heat-white.svg";
import { Widget } from "../../interfaces/modals";
import Button from "../../ui-elements/button/button";
import IconButton from "../../ui-elements/icon-button/icon-button";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
import ProgressBar from "../../ui-elements/progress-bar/progress-bar";
import { showFailureToast, showSuccessToast } from "../../utils";
import { getReadingTypes } from "../../utils/getReadingTypes";
import AreaChartComponent from "../area-chart/area-chart";
import DeleteConfirmationModal from "../modals/delete-confirmation-modal/delete-confirmation-modal";
import styles from "./widget-more-info.module.scss";

interface IProps {
  show: boolean;
  onClose: () => void;
  selectedWidget?: Widget;
  onShowAddUpdateWidget: () => void;
}

const WidgetMoreInfo = (props: IProps) => {
  const { show, selectedWidget, onClose, onShowAddUpdateWidget } = props;
  const [showWidgetDeleteConfirmModal, setShowWidgetDeleteConfirmModal] =
    useState(false);

  const [sensorReadings, setSensorReadings] = useState<number>(0);
  const widgetData = useAppSelector(
    (state) => state.widgetData[selectedWidget?.id?.toString() ?? ""]
  );
  const [data, setData] = useState<{ name: string; value: number }[]>([]);

  const [triggerTelemetryView] = useLazyTelemetryViewQuery();
  const [deleteWidget, { isLoading }] = useDeleteWidgetMutation();

  useEffect(() => {
    if (selectedWidget && show) {
      const selectedParameter = selectedWidget.dataSources[0];
      const time = moment();
      const startTime = time.clone().subtract(24, "hour").toISOString(true);
      const endTime: string = time.clone().toISOString(true);

      let tempCollectedTelemetryData: { name: Moment; value: number }[] = [];
      triggerTelemetryView({
        sensorParamDefId: selectedParameter.SensorParamDefinitionId,
        startTime,
        endTime,
        frequencyInMinutes: 360,
      })
        .unwrap()
        .then((res) => {
          tempCollectedTelemetryData = [
            ...Object.entries(res.data).map(([key, value]) => {
              return {
                name: moment(key),
                value: value,
              };
            }),
          ];

          tempCollectedTelemetryData = tempCollectedTelemetryData.sort(
            (a, b) => {
              const aTime = a.name;
              const bTime = b.name;

              return aTime.isBefore(bTime) ? -1 : aTime.isAfter(bTime) ? 1 : 0;
            }
          );

          const tempChartDataOne: {
            name: string;
            value: number;
          }[] = tempCollectedTelemetryData.map((ctd) => {
            return {
              name: ctd.name.local().format("hh:mm A"),
              value: ctd.value,
            };
          });

          setData(tempChartDataOne);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedWidget, show]);

  const onDelete = async () => {
    if (selectedWidget?.id) {
      await deleteWidget({ widgetId: selectedWidget.id })
        .unwrap()
        .then(() => {
          showSuccessToast("Widget deleted successfully");
          setShowWidgetDeleteConfirmModal(false);
          onClose();
        })
        .catch(() => {
          showFailureToast("Failed to delete the widget");
        });
    }
  };

  useEffect(() => {
    if (selectedWidget) {
      if (widgetData) {
        setSensorReadings(widgetData.dataSources[0].value);
      }
    }
  }, [widgetData, selectedWidget]);

  return (
    <div
      className={styles.widgetMoreInfoContainer}
      style={show ? { right: "0" } : { right: "-567px" }}
    >
      <Container fluid>
        <Row className="align-items-center justify-content-between">
          <Col xs="auto">
            <IconButton
              background="#2F2A89"
              icon={heat}
              width={45}
              height={45}
              padding="10px"
              borderRadius={17}
            />
          </Col>
          <Col>
            <div className={styles.title}>{selectedWidget?.label || ""}</div>
          </Col>
          <Col xs="auto">
            <MaterialIcon
              icon="close"
              className="cursor-pointer"
              size={25}
              onClick={() => onClose()}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className={styles.text01}>Current Value</Col>
          <Col>
            <Row className="align-items-center justify-content-end">
              <Col xs="auto" className={`pe-0 ${styles.text04}`}>
                Last 24 Hours
              </Col>
              <Col xs="auto" className="ps-1">
                <img src={iconDropDown} alt={""} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-end">
          <Col xs="auto" className={`pe-0 ${styles.text02}`}>
            {sensorReadings}
          </Col>
          <Col xs="auto" className={`ps-2 pb-2 ${styles.text03}`}>
            {getReadingTypes(selectedWidget?.dataSources[0]?.viewingUnit)}
          </Col>
        </Row>
        {selectedWidget?.dataSources[0].additionalData?.minValue &&
          selectedWidget?.dataSources[0].additionalData?.maxValue && (
            <Row className="mt-5">
              <Col>
                <Row className="align-items-center">
                  <Col xs="auto" className="pe-0">
                    <MaterialIcon
                      icon="vertical_align_bottom"
                      size={20}
                      color="#383941"
                    />
                  </Col>
                  <Col xs="auto">
                    <div className={styles.text01}>Minimum Value</div>
                  </Col>
                </Row>
                <Row className="align-items-end">
                  <Col xs="auto" className={`pe-0 ${styles.text02}`}>
                    {selectedWidget?.dataSources[0].additionalData?.minValue}
                  </Col>
                  <Col xs="auto" className={`ps-2 pb-2 ${styles.text03}`}>
                    {getReadingTypes(
                      selectedWidget?.dataSources[0].viewingUnit
                    )}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="align-items-center">
                  <Col xs="auto" className="pe-0">
                    <MaterialIcon
                      icon="vertical_align_top"
                      size={20}
                      color="#383941"
                    />
                  </Col>
                  <Col xs="auto">
                    <div className={styles.text01}>Maximum Value</div>
                  </Col>
                </Row>
                <Row className="align-items-end">
                  <Col xs="auto" className={`pe-0 ${styles.text02}`}>
                    {selectedWidget?.dataSources[0].additionalData?.maxValue}
                  </Col>
                  <Col xs="auto" className={`ps-2 pb-2 ${styles.text03}`}>
                    {getReadingTypes(
                      selectedWidget?.dataSources[0].viewingUnit
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        {selectedWidget?.dataSources[0]?.additionalData?.minValue &&
          selectedWidget?.dataSources[0]?.additionalData?.maxValue &&
          selectedWidget?.dataSources[0]?.additionalData?.optimalValue && (
            <>
              <Row className="mt-5">
                <Col className={styles.text01}>Safe Range</Col>
              </Row>
              <Row>
                <Col>
                  <ProgressBar
                    minValue={
                      selectedWidget?.dataSources[0]?.additionalData?.minValue
                    }
                    maxValue={
                      selectedWidget?.dataSources[0]?.additionalData?.maxValue
                    }
                    optimalValue={
                      selectedWidget?.dataSources[0]?.additionalData
                        ?.optimalValue
                    }
                    sensorReadings={sensorReadings}
                  />
                </Col>
              </Row>
            </>
          )}
        <Row className="mt-4 align-items-center">
          <Col xs={"auto"} className={`${styles.text01}`}>
            Sensor
          </Col>
          <Col xs={"auto"} className={`${styles.text06}`}>
            {selectedWidget?.label}
          </Col>
        </Row>
        <Row className="mt-4 align-items-center">
          <Col>
            <div className={styles.chart}>
              <AreaChartComponent data={data} />
            </div>
          </Col>
        </Row>
        <Row className="mt-5 justify-content-end">
          <Col xs="auto">
            <Button
              text="Edit Widget"
              onClick={() => {
                if (selectedWidget) {
                  onShowAddUpdateWidget();
                }
              }}
              variant="Primary"
              borderRadius={24}
              type="button"
              padding="12px 50px"
            />
          </Col>
          <Col xs="auto">
            <Button
              text="Delete Widget"
              onClick={() => setShowWidgetDeleteConfirmModal(true)}
              variant="Danger"
              borderRadius={24}
              type="button"
              padding="12px 40px"
            />
          </Col>
        </Row>
      </Container>
      <DeleteConfirmationModal
        show={showWidgetDeleteConfirmModal}
        onClose={() => setShowWidgetDeleteConfirmModal(false)}
        onConfirm={onDelete}
        title="Remove Widget"
        description="Are you sure you want to remove this widget?"
        isLoading={isLoading}
      />
    </div>
  );
};

export default WidgetMoreInfo;
