import { Col, Container, Row } from "react-bootstrap";
import caution from "../../assets/images/caution.svg";
import { ExecutedTrigger } from "../../interfaces/modals";
import IconButton from "../../ui-elements/icon-button/icon-button";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
import styles from "./exectuted-trigger-card.module.scss";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

interface CardProps {
  executedTrigger: ExecutedTrigger;
  onDelete: (triggerId: string) => void;
}

const ExectutedTriggerCard = (props: CardProps) => {
  const { executedTrigger, onDelete } = props;

  const timeAgo = dayjs(executedTrigger.timestamp).fromNow(true);

  return (
    <Container fluid className={styles.cardContainer}>
      <Row className="align-items-center justify-content-between">
        <Col xs={2}>
          <IconButton
            icon={caution}
            width={40}
            height={40}
            padding="6px"
            borderRadius={17}
          />
        </Col>
        <Col xs={3} className={styles.text01}>
          {props.executedTrigger.label}
        </Col>
        <Col xs={3} className={styles.text02}>
          {timeAgo + " ago"}
        </Col>
        <Col xs={2} className={"d-flex justify-content-end"}>
          <div
            className="cursor-pointer"
            onClick={() => {
              onDelete(executedTrigger.id);
            }}
          >
            <MaterialIcon icon="close" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ExectutedTriggerCard;
