import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import DashboardSideMenu from "../../shared/components/dashboard-side-menu/dashboard-side-menu";
import Tabs from "../../shared/components/tabs/tabs";
import WidgetMoreInfo from "../../shared/components/widget-more-info/widget-more-info";
import { Dashboard, Widget } from "../../shared/interfaces/modals";
import WidgetTypes from "../../shared/utils/getWidgetTypes";
import AddUpdateDashboard from "./components/add-update-dashboard/add-update-dashboard";
import AddUpdateWidget from "./components/add-update-widget/add-update-widget";
import styles from "./dashboards.module.scss";

const defaultDashboardValue: Dashboard = {
  id: 0,
  label: "",
  widgets: [],
};

const Dashboards = () => {
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [showAddUpdateDashboard, setShowAddUpdateDashboard] = useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState<Dashboard>({
    ...defaultDashboardValue,
  });
  const [showSideMenuState, setShowSideMenuState] = useState(true);
  const [showAddUpdateWidget, setShowAddUpdateWidget] = useState(false);
  const [selectedWidget, setSelectedWidget] = useState<Widget>();

  return (
    <>
      <Row>
        <Col>
          <Tabs />
        </Col>
      </Row>
      <div className={`${styles.dashboardContainer} mt-4 d-block d-md-flex`}>
        <div className={`${styles.menu} ${!showSideMenuState && styles.hide}`}>
          <DashboardSideMenu
            onShowAddUpdateDashboard={() => {
              setShowAddUpdateDashboard(true);
              setShowAddUpdateWidget(false);
              setShowMoreInfo(false);
            }}
            onHidedUpdateDashboard={() => {
              setShowAddUpdateDashboard(false);
              setShowAddUpdateWidget(false);
              setShowMoreInfo(false);
            }}
            setShowSideMenuState={setShowSideMenuState}
            setSelectedDashboard={setSelectedDashboard}
            selectedDashboard={selectedDashboard}
          />
        </div>
        <div
          className={`${styles.content} ${
            showSideMenuState && `ms-0 ms-md-3`
          } mt-3 mt-md-0`}
        >
          {showAddUpdateWidget ? (
            <div className={`${styles.addUpdateWidgetContainer} p-4`}>
              <AddUpdateWidget
                onCancel={() => setShowAddUpdateWidget(false)}
                selectedDashboardId={Number(selectedDashboard.id)}
                selectedWidget={selectedWidget}
              />
            </div>
          ) : showAddUpdateDashboard ? (
            <AddUpdateDashboard
              selectedDashboard={selectedDashboard}
              onCancel={() => setShowAddUpdateDashboard(false)}
              onClose={() => setShowAddUpdateDashboard(false)}
            />
          ) : (
            <Row className="g-2">
              {selectedDashboard.widgets.length > 0 ? (
                selectedDashboard.widgets.map((w) => (
                  <Col
                    key={w.id}
                    className={`col-12 ${
                      showSideMenuState ? `col-xl-6` : `col-xl-4`
                    }`}
                  >
                    <WidgetTypes
                      widgetType={w.type}
                      widget={w}
                      onClick={(w, value) => {
                        setSelectedWidget(w);
                        setShowMoreInfo(value);
                      }}
                    />
                  </Col>
                ))
              ) : (
                <>
                  {selectedDashboard.id && (
                    <div className="container-dash text-center text-light font-size-14">
                      No widgets have been added yet. Please click the 'Edit'
                      button to add widgets.
                    </div>
                  )}
                </>
              )}
            </Row>
          )}
        </div>
        <WidgetMoreInfo
          show={showMoreInfo}
          onClose={() => setShowMoreInfo(false)}
          selectedWidget={selectedWidget}
          onShowAddUpdateWidget={() => {
            setShowAddUpdateWidget(true);
            setShowMoreInfo(false);
          }}
        />
      </div>
    </>
  );
};

export default Dashboards;
