import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { v4 as uuid } from "uuid";
import { useLazyViewSensorsQuery } from "../../redux/api/sensor-api/sensor-api";
import {
  useDeleteTrendMutation,
  useLazyTelemetryViewQuery,
  useSaveTrendMutation,
  useUpdateTrendMutation,
} from "../../redux/api/trend-api/trend-api";
import AddParameterButton from "../../shared/components/add-parameter-button/add-parameter-button";
import AddProcessParameterModal from "../../shared/components/add-process-parameter-modal/add-process-parameter-modal";
import Chart, { ChartData, DataSet } from "../../shared/components/chart/chart";
import DeleteConfirmationModal from "../../shared/components/modals/delete-confirmation-modal/delete-confirmation-modal";
import Parameter from "../../shared/components/parameter/parameter";
import Tabs from "../../shared/components/tabs/tabs";
import TrendsSideMenu from "../../shared/components/trends-side-menu/trends-side-menu";
import { GraphType, TimeRange } from "../../shared/enums";
import { ISaveTrendDataSource } from "../../shared/interfaces/dtos/request-dtos/save-trend-request-dto";
import {
  ParameterDefinition,
  Sensor,
  Trend,
} from "../../shared/interfaces/modals";
import AppDatePicker from "../../shared/ui-elements/app-date-picker/app-date-picker";
import AppSelect, {
  Option,
} from "../../shared/ui-elements/app-select/app-select";
import Button from "../../shared/ui-elements/button/button";
import TextField from "../../shared/ui-elements/text-field/text-field";
import {
  frequencies,
  getStartAndEndTimeForTrendsChart,
  graphTypes,
  showFailureToast,
  showSuccessToast,
  timeRanges,
} from "../../shared/utils";
import { getReadingTypes } from "../../shared/utils/getReadingTypes";
import styles from "./manage-trends.module.scss";

const defaultTrendValue: Trend = {
  id: 0,
  label: "",
  timeRange: TimeRange.LAST_30_MINUTES,
  additionalData: {},
  graphType: GraphType.AREA,
  frequencyInMinutes: 0,
  dataSources: [],
};

interface ParameterDefinitionWithColor {
  SensorParamDefinitionId: number;
  color: string;
  sensorParamDefinitionLabel: string;
}

interface Inputs {
  range: Option;
  graph: Option;
  frequency: Option;
  templateName: string;
}

const defaultFormValues: Inputs = {
  range: timeRanges[0],
  graph: graphTypes[0],
  frequency: frequencies[0],
  templateName: "",
};

const ITEMS_PER_PAGE = 500;

interface ICollectedTelemetryData {
  time: Moment;
  SensorParamDefinitionId: number;
  value: number;
}

const ManageTrends = () => {
  const [chartType, setChartType] = useState<GraphType>(GraphType.AREA);

  const [showSideMenuState, setShowSideMenuState] = useState(true);
  const [selectedTrend, setSelectedTrend] = useState<Trend>({
    ...defaultTrendValue,
  });
  const [newlyAddedTrend, setNewlyAddedTrend] = useState(0);
  const [isShowProcessParameterModal, setIsShowProcessParameterModal] =
    useState(false);

  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [selectedSensor, setSelectedSensor] = useState<Option>();
  const [fields, setFields] = useState<ParameterDefinition[]>([]);
  const [selectedField, setSelectedField] = useState<Option>();
  const [selectedColor, setSelectedColor] = useState<string>("#2f2a89");
  const [selectedParameters, setSelectedParameters] = useState<
    ParameterDefinitionWithColor[]
  >([]);
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [dataSet, setDataSet] = useState<DataSet[]>([]);
  const [isParameterAlreadyAdded, setIsParameterAlreadyAdded] = useState(false);
  const [isNotParameterSelected, setIsNotParameterSelected] = useState(false);
  const [isProcessLoading, setIsProcessLoading] = useState(false);
  const [isShowTrendDeleteModal, setIsShowTrendDeleteModal] = useState(false);
  const [isAutoProcess, setIsAutoProcess] = useState(false);
  const [trendDeleteId, setTrendDeleteId] = useState(0);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [invisibleChartData, setInvisibleChartData] = useState<number[]>([]);

  const [triggerTelemetryView] = useLazyTelemetryViewQuery();
  const [triggerSaveTrendMutation] = useSaveTrendMutation();
  const [triggerUpdateTrendMutation] = useUpdateTrendMutation();
  const [triggerDeleteTrendMutation, { isLoading: isLoadingRemoveTrend }] =
    useDeleteTrendMutation();
  const [triggerGetSensors] = useLazyViewSensorsQuery();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    control,
    setValue,
    watch,
    trigger,
  } = useForm<Inputs>({ defaultValues: { ...defaultFormValues } });

  const currentTimeRange = watch("range");

  useEffect(() => {
    if ((currentTimeRange.value as TimeRange) !== TimeRange.CUSTOM) {
      setStartTime(new Date());
      setEndTime(new Date());
    }
  }, [currentTimeRange]);

  useEffect(() => {
    if (selectedTrend.id) {
      setValue(
        "frequency",
        frequencies.find(
          (f) => f.value === String(selectedTrend.frequencyInMinutes)
        ) ?? frequencies[0]
      );
      setValue(
        "graph",
        graphTypes.find((gt) => gt.value === selectedTrend.graphType) ??
          graphTypes[0]
      );
      setValue("templateName", selectedTrend.label);
      setValue(
        "range",
        timeRanges.find((tr) => tr.value === selectedTrend.timeRange) ??
          timeRanges[0]
      );
      setSelectedParameters(
        selectedTrend.dataSources.map((ds) => {
          return {
            color: ds.additionalData.color,
            SensorParamDefinitionId: ds.SensorParamDefinitionId,
            sensorParamDefinitionLabel: ds.sensorParamDefinitionLabel,
          };
        })
      );
      setStartTime(
        selectedTrend.additionalData?.startTime
          ? new Date(selectedTrend.additionalData.startTime)
          : new Date()
      );

      setEndTime(
        selectedTrend.additionalData?.endTime
          ? new Date(selectedTrend.additionalData.endTime)
          : new Date()
      );

      setIsAutoProcess(true);

      trigger();

      return;
    }

    setIsNotParameterSelected(false);
    reset();
    setSelectedParameters([]);
    setChartData([]);
    setDataSet([]);
  }, [selectedTrend]);

  useEffect(() => {
    if (selectedTrend.id && selectedParameters.length > 0 && isAutoProcess) {
      setIsAutoProcess(false);
      onProcess(getValues());
    }
  }, [selectedTrend.id, selectedParameters, isAutoProcess]);

  const onSaveTemplate = (data: Inputs) => {
    if (selectedParameters.length === 0) {
      setIsNotParameterSelected(true);
      return;
    }

    setIsNotParameterSelected(false);

    const dataSources: ISaveTrendDataSource[] = selectedParameters.map((sp) => {
      return {
        sensorParamDefId: sp.SensorParamDefinitionId,
        additionalData: { color: sp.color },
      };
    });

    (selectedTrend.id
      ? triggerUpdateTrendMutation({
          id: selectedTrend.id,
          label: data.templateName,
          timeRange: data.range
            ? (data.range.value as TimeRange)
            : TimeRange.LAST_30_MINUTES,
          type: data.graph ? (data.graph.value as GraphType) : GraphType.AREA,
          frequencyInMinutes: data.frequency ? +data.frequency.value : 1,
          additionalData: {
            startTime: moment(startTime).startOf("day").toISOString(true),
            endTime: moment(endTime).endOf("day").toISOString(true),
          },
          dataSources,
        })
      : triggerSaveTrendMutation({
          label: data.templateName,
          timeRange: data.range
            ? (data.range.value as TimeRange)
            : TimeRange.LAST_30_MINUTES,
          type: data.graph ? (data.graph.value as GraphType) : GraphType.AREA,
          frequencyInMinutes: data.frequency ? +data.frequency.value : 1,
          additionalData: {
            startTime: moment(startTime).startOf("day").toISOString(true),
            endTime: moment(endTime).endOf("day").toISOString(true),
          },
          dataSources,
        })
    )
      .unwrap()
      .then((res) => {
        if (selectedTrend.id) {
          onProcess(getValues());
        } else {
          setNewlyAddedTrend(res.id);
        }
        showSuccessToast(
          `Trend template successfully ${
            selectedTrend.id ? "updated" : "added"
          }`
        );
      })
      .catch(() => {
        showFailureToast(
          `${selectedTrend.id ? "Updating" : "Adding"} trend template failed`
        );
      });
  };

  const onProcess = async (data: Inputs) => {
    if (selectedParameters.length === 0) {
      setIsNotParameterSelected(true);
      return;
    }

    setIsNotParameterSelected(false);
    setIsProcessLoading(true);

    let start: string;
    let end: string;

    if (!data.range || (data.range.value as TimeRange) !== TimeRange.CUSTOM) {
      const times = getStartAndEndTimeForTrendsChart(
        data.range ? (data.range.value as TimeRange) : TimeRange.LAST_30_MINUTES
      );

      start = times.startTime;
      end = times.endTime;
    } else {
      start = moment(startTime).startOf("day").toISOString(true);
      end = moment(endTime).endOf("day").toISOString(true);
    }

    const tempDataSet: DataSet[] = [];
    let tempCollectedTelemetryData: ICollectedTelemetryData[] = [];

    for (const sp of selectedParameters) {
      await triggerTelemetryView({
        sensorParamDefId: sp.SensorParamDefinitionId,
        startTime: start,
        endTime: end,
        frequencyInMinutes: data.frequency ? +data.frequency.value : 1,
      })
        .unwrap()
        .then((res) => {
          tempDataSet.push({
            sensorParamDefId: sp.SensorParamDefinitionId,
            color: sp.color,
            name: sp.sensorParamDefinitionLabel,
            unit: getReadingTypes(res.unit),
            dataKey: "dataKey" + sp.SensorParamDefinitionId,
          });

          tempCollectedTelemetryData = [
            ...tempCollectedTelemetryData,
            ...Object.entries(res.data).map(([key, value]) => {
              return {
                time: moment(key),
                SensorParamDefinitionId: sp.SensorParamDefinitionId,
                value: +value,
              };
            }),
          ];

          tempCollectedTelemetryData = tempCollectedTelemetryData.sort(
            (a, b) => {
              const aTime = a.time;
              const bTime = b.time;

              return aTime.isBefore(bTime) ? -1 : aTime.isAfter(bTime) ? 1 : 0;
            }
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const tempChartDataOne: {
      time: string;
      [key: string]: number | string;
    }[] = tempCollectedTelemetryData.map((ctd) => {
      return {
        time:
          data.frequency.value === "0.5"
            ? ctd.time.local().format("hh:mm:ss A")
            : ctd.time.local().format("hh:mm A"),
        ["dataKey" + ctd.SensorParamDefinitionId]: ctd.value,
      };
    });

    const tempChartDataTwo: {
      time: string;
      [key: string]: number | string;
    }[] = [];

    tempChartDataOne.forEach((tcd) => {
      let foundIndex = tempChartDataTwo.findIndex(
        (tcd2) => tcd2.time === tcd.time
      );

      if (foundIndex !== -1) {
        const newTcd: { [key: string]: number | string } = { ...tcd };
        delete newTcd.time;
        tempChartDataTwo[foundIndex] = {
          ...tempChartDataTwo[foundIndex],
          ...newTcd,
        };
      } else {
        tempChartDataTwo.push(tcd);
      }
    });

    setDataSet(tempDataSet);

    setChartData(
      tempChartDataTwo.map((tcdt) => {
        const newObj = { ...tcdt };

        tempDataSet.forEach((tds) => {
          newObj[tds.dataKey] = tcdt[tds.dataKey] || 0;
        });

        return newObj;
      })
    );
    setChartType(data.graph ? (data.graph.value as GraphType) : GraphType.AREA);
    setIsProcessLoading(false);
  };

  useEffect(() => {
    triggerGetSensors({ page: 0, size: ITEMS_PER_PAGE })
      .unwrap()
      .then((res) => {
        const sensors = res.sensors;
        const sensor = sensors[0];
        setSensors(sensors);
        setSelectedSensor({
          label: sensor.label,
          value: sensor.label,
          data: sensor,
        });
        setFields(sensor.parameterDefinitions);
        setSelectedField({
          label: sensor.parameterDefinitions[0].label,
          value: sensor.parameterDefinitions[0].label,
          data: sensor.parameterDefinitions[0],
        });
      })
      .catch(() => {
        setSensors([]);
        setSelectedSensor(undefined);
        setFields([]);
        setSelectedField(undefined);
      });
  }, [triggerGetSensors]);

  useEffect(() => {
    if (selectedSensor?.data) {
      const parameterDefinitions = selectedSensor.data.parameterDefinitions;
      setFields(parameterDefinitions);
      setSelectedField({
        label: parameterDefinitions[0].label,
        value: parameterDefinitions[0].label,
        data: parameterDefinitions[0],
      });
    }
  }, [selectedSensor]);

  const onCloseAddParameterModal = () => {
    if (sensors.length > 0) {
      const sensor = sensors[0];
      setSelectedSensor({
        label: sensor.label,
        value: sensor.label,
        data: sensor,
      });
      setFields(sensor.parameterDefinitions);
      setSelectedField({
        label: sensor.parameterDefinitions[0].label,
        value: sensor.parameterDefinitions[0].label,
        data: sensor.parameterDefinitions[0],
      });
    } else {
      setSelectedSensor(undefined);
      setFields([]);
      setSelectedField(undefined);
    }

    setSelectedColor("#2f2a89");
    setIsShowProcessParameterModal(false);
  };

  const onAdd = () => {
    const isFound = selectedParameters.find(
      (sp) => sp.SensorParamDefinitionId === selectedField?.data.id
    );

    if (!isFound) {
      setSelectedParameters((ps) => {
        return [
          ...ps,
          {
            SensorParamDefinitionId: selectedField?.data.id,
            color: selectedColor,
            sensorParamDefinitionLabel: selectedField?.data.label,
          },
        ];
      });
      setIsParameterAlreadyAdded(false);
      onCloseAddParameterModal();
      return;
    }

    setIsParameterAlreadyAdded(true);
  };

  const onParameterRemove = (id: number) => {
    setSelectedParameters((ps) =>
      ps.filter((sp) => sp.SensorParamDefinitionId !== id)
    );
  };

  const onTrendDeleteConfirmation = (id: number) => {
    setTrendDeleteId(id);
    setIsShowTrendDeleteModal(true);
  };

  const onTrendDelete = () => {
    triggerDeleteTrendMutation({ id: trendDeleteId })
      .unwrap()
      .then(() => {
        if (selectedTrend.id === trendDeleteId) {
          reset();
          setSelectedParameters([]);
          setSelectedTrend({ ...defaultTrendValue });
        }
        showSuccessToast("Trend template deleted successfully");
      })
      .catch(() => {
        showFailureToast("Deleting trend template failed");
      })
      .finally(() => {
        setIsShowTrendDeleteModal(false);
        setTrendDeleteId(0);
      });
  };

  return (
    <>
      <Row className="row-cols-auto gx-2">
        <Col>
          <Tabs />
        </Col>
      </Row>
      <div className={`${styles.trendsContainer} mt-4 d-block d-md-flex`}>
        <div className={`${styles.menu} ${!showSideMenuState && styles.hide}`}>
          <TrendsSideMenu
            selectedTrend={selectedTrend}
            newlyAddedTrend={newlyAddedTrend}
            setShowSideMenuState={setShowSideMenuState}
            setSelectedTrend={setSelectedTrend}
            onDelete={onTrendDeleteConfirmation}
          />
        </div>
        <Container
          fluid
          className={`${styles.content} ${showSideMenuState && `ms-3`}`}
        >
          <Row className={`${styles.filterCard} p-0 py-4 p-md-4`}>
            <Col xs={12}>
              <Row className="mt-3">
                <Col xs={6}>
                  <TextField
                    name="templateName"
                    placeholder="Template Name"
                    label="Template Name"
                    register={register("templateName", {
                      required: "Template name is required",
                    })}
                    errors={errors}
                  />
                </Col>
                <Col xs={6}>
                  <AppSelect
                    defaultValue={""}
                    placeholder="Range"
                    options={timeRanges}
                    label="Range"
                    control={control}
                    name="range"
                    errors={errors}
                    register={register("range", {
                      required: "Please select a range",
                    })}
                  />
                </Col>
              </Row>
              {currentTimeRange.value === TimeRange.CUSTOM && (
                <Row className="mt-3">
                  <Col xs={12} sm={6}>
                    <AppDatePicker
                      label="From"
                      selectedDate={startTime}
                      onChange={(date) => setStartTime(date)}
                      dateFormat="yyyy-MMMM-dd"
                      fontSize={14}
                      datePickerTextClassName="font-weight-400"
                      maxDate={new Date()}
                    />
                  </Col>
                  <Col className="mt-3 mt-sm-0">
                    <AppDatePicker
                      label="To"
                      selectedDate={endTime}
                      onChange={(date) => setEndTime(date)}
                      dateFormat="yyyy-MMMM-dd"
                      fontSize={14}
                      datePickerTextClassName="font-weight-400"
                      minDate={startTime}
                      maxDate={new Date()}
                    />
                  </Col>
                </Row>
              )}
              <Row className="mt-3">
                <Col xs={6}>
                  <AppSelect
                    defaultValue={""}
                    placeholder="Graph"
                    options={graphTypes.slice(0, 3)}
                    label="Graph"
                    control={control}
                    name="graph"
                    errors={errors}
                    register={register("graph", {
                      required: "Please select a graph",
                    })}
                  />
                </Col>
                <Col xs={6}>
                  <AppSelect
                    defaultValue={""}
                    placeholder="Frequency"
                    options={frequencies}
                    label="Frequency"
                    control={control}
                    name="frequency"
                    errors={errors}
                    register={register("frequency", {
                      required: "Please select a frequency",
                    })}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12}>
                  <Row>
                    <Col className="text-dark">Process Parameters</Col>
                  </Row>
                  {isNotParameterSelected && (
                    <Row className="mt-2">
                      <Col className="error">Select at least one parameter</Col>
                    </Row>
                  )}
                  <Row className="align-items-center">
                    {[
                      ...selectedParameters.map((sp) => {
                        return (
                          <Col key={uuid()} className="col-auto px-4 mt-1">
                            <Parameter
                              text={sp.sensorParamDefinitionLabel}
                              color={sp.color}
                              onRemove={() => {
                                onParameterRemove(sp.SensorParamDefinitionId);
                              }}
                              sensorParamDefId={sp.SensorParamDefinitionId}
                              invisibleChartData={invisibleChartData}
                              setInvisibleChartData={setInvisibleChartData}
                            />
                          </Col>
                        );
                      }),
                      <Col key={uuid()} className="col-auto px-4 mt-1">
                        <AddParameterButton
                          onAdd={() => {
                            setIsShowProcessParameterModal(true);
                          }}
                        />
                      </Col>,
                    ]}
                  </Row>
                </Col>
              </Row>
              <Row className="mt-5 justify-content-between g-2">
                <Col xs={12} md={"auto"}>
                  <Button
                    text={
                      selectedTrend.id ? "Update Template" : "Save Template"
                    }
                    variant="Outline"
                    className="w-100"
                    borderRadius={24}
                    type="button"
                    padding="12px 50px"
                    onClick={handleSubmit(onSaveTemplate)}
                  />
                </Col>
                <Col xs={12} md={"auto"}>
                  <Button
                    text="Process"
                    variant="Primary"
                    className="w-100"
                    borderRadius={24}
                    type="button"
                    padding="12px 60px"
                    onClick={() => {
                      onProcess(getValues());
                    }}
                    isLoading={isProcessLoading}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className={styles.chartCard}>
              <Chart
                data={chartData}
                chartType={chartType}
                dataSet={dataSet}
                isLoading={isProcessLoading}
                invisibleData={invisibleChartData}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <AddProcessParameterModal
        show={isShowProcessParameterModal}
        onClose={onCloseAddParameterModal}
        onConfirm={onAdd}
        sensors={sensors}
        fields={fields}
        selectedSensor={selectedSensor}
        selectedField={selectedField}
        selectedColor={selectedColor}
        setSelectedColor={setSelectedColor}
        setSelectedSensor={setSelectedSensor}
        setSelectedField={setSelectedField}
        isParameterAlreadyAdded={isParameterAlreadyAdded}
      />
      <DeleteConfirmationModal
        show={isShowTrendDeleteModal}
        onClose={() => setIsShowTrendDeleteModal(false)}
        onConfirm={onTrendDelete}
        title="Remove Trend"
        description="Are you sure you want to remove this trend?"
        isLoading={isLoadingRemoveTrend}
      />
    </>
  );
};

export default ManageTrends;
