import { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { ParameterDefinition, Sensor } from "../../interfaces/modals";
import AppModal from "../../ui-elements/app-modal/app-modal";
import AppSelect, { Option } from "../../ui-elements/app-select/app-select";
import Button from "../../ui-elements/button/button";
import styles from "./add-process-parameter-modal.module.scss";

interface IProps {
  show: boolean;
  sensors: Sensor[];
  fields: ParameterDefinition[];
  selectedSensor: Option | undefined;
  selectedField: Option | undefined;
  selectedColor: string;
  isParameterAlreadyAdded: boolean;
  setSelectedColor: React.Dispatch<React.SetStateAction<string>>;
  setSelectedSensor: React.Dispatch<React.SetStateAction<Option | undefined>>;
  setSelectedField: React.Dispatch<React.SetStateAction<Option | undefined>>;
  onClose: () => void;
  onConfirm: () => void;
}

const AddProcessParameterModal = (props: IProps) => {
  const colorInputRef = useRef<HTMLInputElement>(null);

  const {
    show,
    sensors,
    selectedSensor,
    selectedField,
    fields,
    selectedColor,
    isParameterAlreadyAdded,
    setSelectedColor,
    onClose,
    onConfirm,
    setSelectedSensor,
    setSelectedField,
  } = props;

  const onChangeColorButtonClick = () => {
    if (colorInputRef.current) {
      colorInputRef.current.click();
    }
  };

  return (
    <AppModal show={show} onClose={onClose} width={500}>
      <Row>
        <Col className="text-dark text-center">Add Process Parameter</Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <AppSelect
            label="Sensor"
            options={sensors.map((sensor) => {
              return { label: sensor.label, value: sensor.label, data: sensor };
            })}
            selectedValue={selectedSensor}
            onChangeOption={(selectedSensor) => {
              setSelectedSensor(selectedSensor);
            }}
            menuHeight="150px"
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <AppSelect
            label="Parameter"
            options={fields.map((parameter) => {
              return {
                label: parameter.label,
                value: parameter.label,
                data: parameter,
              };
            })}
            selectedValue={selectedField}
            onChangeOption={(selectedField) => {
              setSelectedField(selectedField);
            }}
            menuHeight="150px"
          />
        </Col>
      </Row>
      <Row className="flex-column mt-4">
        <Col>Color</Col>
        <Col>
          <Row className="align-items-center">
            <Col className="col-auto">
              <input
                type="color"
                id="colorPicker"
                value={selectedColor}
                onChange={(color) => {
                  setSelectedColor(color.target.value);
                }}
                className={`${styles.colorStyle} cursor-pointer`}
                ref={colorInputRef}
              />
            </Col>
            <Col
              className="col-auto text-primary cursor-pointer"
              onClick={onChangeColorButtonClick}
            >
              Change Color
            </Col>
          </Row>
        </Col>
      </Row>
      {isParameterAlreadyAdded && (
        <Row className="mt-4">
          <Col className="error">Parameter already added</Col>
        </Row>
      )}
      <Row className="justify-content-center mt-4">
        <Col sm={3}>
          <Button
            text="Cancel"
            className="rounded-pill"
            variant="Outline"
            onClick={onClose}
          />
        </Col>
        <Col sm={3}>
          <Button text="Add" className="rounded-pill" onClick={onConfirm} />
        </Col>
      </Row>
    </AppModal>
  );
};

export default AddProcessParameterModal;
