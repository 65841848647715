import { Col, Container, Row } from "react-bootstrap";
import icon from "../../../assets/images/package_2.svg";
import { WidgetType } from "../../../enums";
import { EColors } from "../../../enums/colors";
import IconButton from "../../../ui-elements/icon-button/icon-button";
import { getWidgetTextColor } from "../../../utils/getWidgetTextColor";
import styles from "./counter-widget.module.scss";

interface IProps {
  type: WidgetType;
  textColor: EColors;
}

const CounterWidget = (props: IProps) => {
  return (
    <Container
      fluid
      className={`${styles.widgetContainer} ${
        props.type === WidgetType.WIDGET_TYPE_01 && styles.selectedWidget
      } p-4`}
    >
      <Row>
        <Col className={styles.title}>Main Room Inverter</Col>
      </Row>
      <Row className="align-items-center mt-4">
        <Col xs="auto" className="pe-0">
          <IconButton padding={"12px"} icon={icon} width={56} height={56} />
        </Col>
        <Col>
          <Row className="align-items-center">
            <Col
              xs="auto"
              className={styles.value}
              style={{
                color: `${getWidgetTextColor(props.textColor as EColors)}`,
              }}
            >
              5633
            </Col>
            <Col xs="auto" className={`ps-0 mt-3 ${styles.unit}`}>
              KW
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default CounterWidget;
