import { Col, Container, Row } from "react-bootstrap";
import arrow from "../../../assets/images/arrow.svg";
import dropDown from "../../../assets/images/arrow_drop_down.svg";
import { ReadingUnit } from "../../../enums";
import { EColors } from "../../../enums/colors";
import IconButton from "../../../ui-elements/icon-button/icon-button";
import { getReadingTypes } from "../../../utils/getReadingTypes";
import { getWidgetTextColor } from "../../../utils/getWidgetTextColor";
import styles from "./widget-amount.module.scss";

interface IProps {
  title: string;
  icon: string;
  value: number;
  unit: string;
  onClick: () => void;
  textColor: string;
}

const WidgetAmount = (props: IProps) => {
  return (
    <Container fluid className={`${styles.widgetContainer} p-4`}>
      <Row className="justify-content-between">
        <Col xs="auto">
          <div className={styles.title}>{props.title}</div>
          <Row className={`align-items-center ${styles.subTitle}`}>
            <Col xs={"auto"} className={"pe-0"}>
              Today
            </Col>
            <Col xs={"auto"} className={"ps-2"}>
              <img src={dropDown} alt={""} />
            </Col>
          </Row>
        </Col>
        <Col xs={"auto"}>
          <IconButton
            icon={arrow}
            width={60}
            height={40}
            onClick={() => {
              props.onClick();
            }}
            background="#2F2A89"
            boxShadow="-4px 10px 22px 0px rgba(47, 42, 137, 0.42)"
            padding="8px 20px"
            borderRadius={16}
          />
        </Col>
      </Row>
      <Row className="align-items-center mt-4">
        <Col xs="auto">
          <IconButton
            padding={"12px"}
            icon={props.icon}
            width={56}
            height={56}
          />
        </Col>
        <Col>
          <Row className="align-items-end">
            <Col
              xs="auto"
              className={styles.value}
              style={{
                color: `${getWidgetTextColor(props.textColor as EColors)}`,
              }}
            >
              {props.value}
            </Col>
            <Col xs="auto" className={`ps-0 ${styles.unit}`}>
              {getReadingTypes(props.unit as ReadingUnit) || props.unit}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default WidgetAmount;
