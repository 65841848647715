import AddWidgetRequestDTO from "../../../shared/interfaces/dtos/request-dtos/add-widget-request-dto";
import UpdateWidgetRequestDTO from "../../../shared/interfaces/dtos/request-dtos/update-widget-request-dto";
import UpdateWidgetResponseDTO from "../../../shared/interfaces/dtos/response-dtos/update-widget-response-dto";
import { rootApi } from "../api-manager";

// Define a service using a base URL and expected endpoints
export const widgetAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addWidget: builder.mutation<{ id: string }, AddWidgetRequestDTO>({
      query: (data) => ({
        url: `/widget`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["dashboards"],
    }),
    deleteWidget: builder.mutation<void, { widgetId: number }>({
      query({ widgetId }) {
        return {
          url: `/widget/${widgetId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["dashboards"],
    }),
    updateWidget: builder.mutation<
      UpdateWidgetResponseDTO,
      UpdateWidgetRequestDTO
    >({
      query: (data: UpdateWidgetRequestDTO) => ({
        url: `/widget/${data.widgetId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["dashboards"],
    }),
  }),
});

export const {
  useAddWidgetMutation,
  useUpdateWidgetMutation,
  useDeleteWidgetMutation,
} = widgetAPI;
