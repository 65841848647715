import { Col, Row } from "react-bootstrap";
import { EColors } from "../../enums/colors";
import styles from "./colors.module.scss";

interface IColorInterface {
  colorName: EColors;
}

interface IProps {
  colors: IColorInterface[];
  onChange: (color: EColors) => void;
  selectedColor: EColors;
}

const Colors = (props: IProps) => {
  const { colors, onChange, selectedColor } = props;

  const getColor = (color: EColors) => {
    switch (color) {
      case EColors.YELLOW:
        return styles.YELLOW;
      case EColors.RED:
        return styles.RED;
      case EColors.GREEN:
        return styles.GREEN;
      case EColors.BLUE:
        return styles.BLUE;
      case EColors.PURPLE:
        return styles.PURPLE;
      default:
        return "";
    }
  };

  return (
    <Row className="row-cols-auto mx-0 gap-2">
      {colors.map((c) => (
        <Col
          key={c.colorName}
          className={`p-4 rounded-1 cursor-pointer ${getColor(c.colorName)} ${
            selectedColor === c.colorName && styles.selectedColor
          }`}
          onClick={() => onChange(c.colorName)}
        ></Col>
      ))}
    </Row>
  );
};

export default Colors;
