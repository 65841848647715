import AddTriggerRequestDTO from "../../../shared/interfaces/dtos/request-dtos/add-trigger-request-dto";
import ViewExecutedTriggerResponseDTO from "../../../shared/interfaces/dtos/response-dtos/view-executed-trigger-response-dto";
import ViewTriggerResponseDTO from "../../../shared/interfaces/dtos/response-dtos/view-trigger-response-dto";
import { rootApi } from "../api-manager";

export const triggerAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addTrigger: builder.mutation({
      query: (trigger: AddTriggerRequestDTO) => ({
        url: "/trigger",
        method: "POST",
        body: trigger,
      }),
      invalidatesTags: ["triggers"],
    }),
    updateTrigger: builder.mutation({
      query: (trigger: AddTriggerRequestDTO) => ({
        url: `/trigger/${trigger.id}`,
        method: "PUT",
        body: trigger,
      }),
      invalidatesTags: ["triggers"],
    }),
    viewTriggers: builder.query<
      ViewTriggerResponseDTO,
      { page?: number; size?: number } | void
    >({
      query: (args) => {
        const params = args ? { ...args } : undefined;

        return {
          url: `/trigger/all`,
          method: "GET",
          params,
        };
      },
      providesTags: ["triggers"],
    }),
    deleteTrigger: builder.mutation<void, string>({
      query: (triggerId: string) => ({
        url: `/trigger/${triggerId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["triggers"],
    }),
    viewExecutedTriggers: builder.query<
      ViewExecutedTriggerResponseDTO,
      { page?: number; size?: number } | void
    >({
      query: (args) => {
        const params = args ? { ...args } : undefined;

        return {
          url: `/trigger/all/executed`,
          method: "GET",
          params,
        };
      },
      providesTags: ["triggers"],
    }),
  }),
});

export const {
  useAddTriggerMutation,
  useUpdateTriggerMutation,
  useViewTriggersQuery,
  useDeleteTriggerMutation,
  useLazyViewTriggersQuery,
  useLazyViewExecutedTriggersQuery,
} = triggerAPI;
