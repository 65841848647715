import { Col, Container, Row } from "react-bootstrap";
import iconSensor from "../../assets/images/sensor-white.svg";
import { Trigger } from "../../interfaces/modals";
import IconButton from "../../ui-elements/icon-button/icon-button";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
import styles from "./trigger-card.module.scss";

interface CardProps {
  trigger: Trigger;
  onDelete: (triggerId: string) => void;
  onEdit: (trigger: Trigger) => void;
}

const TriggerCard = (props: CardProps) => {
  const { trigger, onDelete, onEdit } = props;

  return (
    <Container fluid className={styles.cardContainer}>
      <Row className="align-items-center justify-content-between">
        <Col xs={2}>
          <IconButton
            background="#2F2A89"
            icon={iconSensor}
            width={40}
            height={40}
            padding="6px"
            borderRadius={17}
          />
        </Col>
        <Col xs={3} className={styles.text01}>
          {props.trigger.label}
        </Col>
        <Col xs={3} className={styles.text02}>
          {props.trigger.deviceId}
        </Col>
        <Col xs={2} className={"d-flex justify-content-end"}>
          <div
            className="cursor-pointer"
            onClick={() => {
              onDelete(trigger.id);
            }}
          >
            <MaterialIcon icon="delete" className="text-danger" />
          </div>
        </Col>
        <Col xs="auto" className={"d-flex justify-content-end"}>
          <MaterialIcon
            icon="edit_square"
            className="text-light cursor-pointer"
            onClick={() => {
              onEdit(trigger);
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default TriggerCard;
