import { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAddUserMutation } from "../../../../redux/api/user-api/user-api";
import backICon from "../../../../shared/assets/images/arrow-back.svg";
import { EUserRole } from "../../../../shared/enums";
import AppSelect, {
  Option,
} from "../../../../shared/ui-elements/app-select/app-select";
import Button from "../../../../shared/ui-elements/button/button";
import IconButton from "../../../../shared/ui-elements/icon-button/icon-button";
import TextField from "../../../../shared/ui-elements/text-field/text-field";
import { showFailureToast, showSuccessToast } from "../../../../shared/utils";
import {
  emailRegex,
  mobileNumberRegex,
  nicRegex,
  noSpecialCharsNotAllowOnlyNumbers,
  passwordRegexSixToTwenty,
} from "../../../../shared/utils/regex";
import styles from "./add-update-user.module.scss";

interface IFormInput {
  firstName: string;
  lastName: string;
  username: string;
  mobileNumber: string;
  nic: string;
  email: string;
  password: string;
  confirmPassword: string;
  role: Option | [];
}

const defaultFormValues: IFormInput = {
  firstName: "",
  lastName: "",
  username: "",
  mobileNumber: "",
  nic: "",
  email: "",
  password: "",
  confirmPassword: "",
  role: [],
};

const roleTypes: Option[] = [
  { value: EUserRole.SUPERVISOR, label: "Supervisor" },
  { value: EUserRole.ENGINEER, label: "Engineer" },
  { value: EUserRole.OPERATOR, label: "Operator" },
];

const AddUpdateUser = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [addUser, { isLoading: isLoadingAddUser }] = useAddUserMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<IFormInput>({
    defaultValues: { ...defaultFormValues },
  });

  const password = useRef<string | null>("");
  password.current = watch("password", "");

  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    const role = (data.role as Option).value as EUserRole;

    addUser({
      firstName: data.firstName,
      lastName: data.lastName,
      username: data.username,
      mobileNumber: data.mobileNumber,
      email: data.email,
      password: data.password,
      nic: data.nic,
      role: role,
    })
      .then(() => {
        showSuccessToast("User added successfully");
        navigate(-1);
      })
      .catch(() => {
        showFailureToast("user added unsuccessfully");
      });
  };

  return (
    <div className={`${styles.container} py-3 px-4`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="align-items-center">
          <Col className="col-auto pe-0">
            <IconButton
              background="#2F2A89"
              icon={backICon}
              width={44}
              height={44}
              padding="12px"
              borderRadius={17}
              onClick={() => navigate(-1)}
            />
          </Col>
          <Col className={styles.txt01}>Add User</Col>
        </Row>
        <Row className="row-cols-1 row-cols-sm-2 mt-3 gy-4">
          <Col>
            <TextField
              label="First Name"
              name="firstName"
              placeholder="First Name"
              register={register("firstName", {
                required: "First name is required",
                minLength: {
                  value: 1,
                  message: "This field must contain at least 1 characters",
                },
                maxLength: {
                  value: 50,
                  message:
                    "You have exceeded the maximum number of 50 characters in this field",
                },
                pattern: {
                  value: noSpecialCharsNotAllowOnlyNumbers,
                  message:
                    "Entered value can't start/end or contain only white spaces and can't contain only numbers and special characters",
                },
              })}
              errors={errors}
            />
          </Col>
          <Col>
            <TextField
              label="Mobile Number"
              type="number"
              name="mobileNumber"
              placeholder="Mobile Number"
              register={register("mobileNumber", {
                required: "Mobile number is required",
                pattern: {
                  value: mobileNumberRegex,
                  message: "Please enter a valid Mobile number",
                },
              })}
              errors={errors}
            />
          </Col>
          <Col>
            <TextField
              label="Last Name"
              name="lastName"
              placeholder="Last Name"
              register={register("lastName", {
                required: "Last name is required",
                minLength: {
                  value: 1,
                  message: "This field must contain at least 1 characters",
                },
                maxLength: {
                  value: 50,
                  message:
                    "You have exceeded the maximum number of 50 characters in this field",
                },
                pattern: {
                  value: noSpecialCharsNotAllowOnlyNumbers,
                  message:
                    "Entered value can't start/end or contain only white spaces and can't contain only numbers and special characters",
                },
              })}
              errors={errors}
            />
          </Col>
          <Col>
            <TextField
              label="NIC"
              name="nic"
              placeholder="NIC"
              register={register("nic", {
                required: "NIC is required",
                pattern: {
                  value: nicRegex,
                  message: "Please enter a valid NIC",
                },
              })}
              errors={errors}
            />
          </Col>
          <Col>
            <TextField
              label="Username"
              name="username"
              placeholder="Username"
              register={register("username", {
                required: "Username is required",
                minLength: {
                  value: 1,
                  message: "This field must contain at least 1 characters",
                },
                maxLength: {
                  value: 50,
                  message:
                    "You have exceeded the maximum number of 50 characters in this field",
                },
                pattern: {
                  value: noSpecialCharsNotAllowOnlyNumbers,
                  message:
                    "Entered value can't start/end or contain only white spaces and can't contain only numbers and special characters",
                },
              })}
              errors={errors}
            />
          </Col>
          <Col>
            <AppSelect
              label="Role"
              name="role"
              placeholder="Role"
              options={roleTypes}
              register={register("role", {
                required: "Please select the user role",
              })}
              errors={errors}
              isRequired={true}
              control={control}
            />
          </Col>
          <Col>
            <TextField
              name="password"
              icon={!showPassword ? "visibility_off" : "visibility"}
              placeholder="Password"
              onIconClick={() => setShowPassword(!showPassword)}
              label="Password"
              type={showPassword ? "text" : "password"}
              register={register("password", {
                required: "Password is required",
                pattern: {
                  value: passwordRegexSixToTwenty,
                  message:
                    "Password must have 6-20 characters and include at least one lowercase letter, one uppercase letter, one numeric and one special character",
                },
              })}
              errors={errors}
            />
          </Col>
          <Col>
            <TextField
              label="Email"
              placeholder="Email"
              name="email"
              errors={errors}
              register={register("email", {
                pattern: {
                  value: emailRegex,
                  message: "Please enter a valid email",
                },
              })}
              isRequired={false}
            />
          </Col>
          <Col>
            <TextField
              name="confirmPassword"
              icon={!showConfirmPassword ? "visibility_off" : "visibility"}
              placeholder="Confirm Password"
              onIconClick={() => setShowConfirmPassword(!showConfirmPassword)}
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              register={register("confirmPassword", {
                required: "Password confirmation is required",
                validate: (value) =>
                  value === password.current || "Password doesn't match",
              })}
              errors={errors}
            />
          </Col>
        </Row>
        <Row className="mt-5 pt-3 mb-3 justify-content-end">
          <Col xs="auto">
            <Button
              text="Add User"
              variant="Primary"
              borderRadius={24}
              padding="12px 80px"
              type="submit"
              isLoading={isLoadingAddUser}
            />
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default AddUpdateUser;
