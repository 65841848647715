import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import { IHttpError } from "../../shared/interfaces/http-errror";
import { setToken } from "../slices/auth-slice";
import { toast } from "react-toastify";

export const rtkQueryErrorLogger: Middleware = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const error = action.payload as IHttpError;

    const wwwAuthenticate = (
      action?.meta as { baseQueryMeta?: { response?: Response } }
    )?.baseQueryMeta?.response?.headers?.get("www-authenticate");

    switch (error.status) {
      case 500:
        console.log(error);
        break;
      case 401:
        if (wwwAuthenticate && wwwAuthenticate.includes("invalid_token")) {
          localStorage.clear();
          api.dispatch(setToken(""));
          toast.error("Session expired. Please log in again.");
        }
        break;
      default:
        break;
    }
  }

  return next(action);
};
