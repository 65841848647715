import { Col, Row } from "react-bootstrap";
import { WidgetType } from "../enums";

export interface IWidgetComponentInterface {
  widgetName: string;
  widget: JSX.Element;
  widgetType: WidgetType;
}

interface IProps {
  widgetTemplates: IWidgetComponentInterface[];
  onChange: (widgetType: WidgetType) => void;
}

const WidgetTemplates = (props: IProps) => {
  const { widgetTemplates, onChange } = props;

  return (
    <Row className="gy-3">
      {widgetTemplates.map((w, index) => {
        return (
          <Col
            key={index}
            className="col-12 col-lg-6 col-xl-4"
            onClick={() => onChange(w.widgetType)}
          >
            {w.widget}
          </Col>
        );
      })}
    </Row>
  );
};

export default WidgetTemplates;
