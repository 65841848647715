import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setToken } from "../../../redux/slices/auth-slice";
import { Pages } from "../../../screens/routes";
import logo from "../../assets/images/bolt.svg";
import trendsInactiveIcon from "../../assets/images/chart_data.svg";
import trendsActiveIcon from "../../assets/images/chart_data_active.svg";
import logoutIcon from "../../assets/images/logout.svg";
import alertsActiveIcon from "../../assets/images/notifications-active.svg";
import alertsInactiveIcon from "../../assets/images/notifications.svg";
import sensorActiveIcon from "../../assets/images/sensor-nav-bar-active.svg";
import sensorInactiveIcon from "../../assets/images/sensor-nav-bar.svg";
import settingActiveIcon from "../../assets/images/settings-active.svg";
import settingInactiveIcon from "../../assets/images/settings.svg";
import workflowActiveIcon from "../../assets/images/stacks-active.svg";
import workflowInactiveIcon from "../../assets/images/stacks.svg";
import AppModal from "../../ui-elements/app-modal/app-modal";
import Avatar from "../../ui-elements/avatar/avatar";
import Button from "../../ui-elements/button/button";
import IconButton from "../../ui-elements/icon-button/icon-button";
import styles from "./nav-bar.module.scss";

interface IProps {
  onSideMenuHide: () => void;
  setShowSideMenuFirstTime: React.Dispatch<React.SetStateAction<boolean>>;
  showSideMenu: boolean;
}

const NavBar = (props: IProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const logout = () => {
    localStorage.clear();
    dispatch(setToken(""));
    navigate(Pages.login);
  };

  return (
    <div className={`${styles.navbar} d-none d-md-block`}>
      <div className={styles.content}>
        <div className={styles.top}>
          <IconButton icon={logo} width={40} height={40} />
          <IconButton
            icon={
              pathname === Pages.dashboards
                ? workflowActiveIcon
                : workflowInactiveIcon
            }
            margin="30px 0 0 0"
            onClick={() => {
              navigate(Pages.dashboards);
              pathname !== Pages.dashboards &&
                props.setShowSideMenuFirstTime(true);
            }}
            width={40}
            height={40}
            padding="8px"
            background={pathname === Pages.dashboards ? "#D5D4E7" : ""}
            borderRadius={4}
            isSideMenu={pathname === Pages.dashboards}
            onClickSideMenu={props.onSideMenuHide}
            isApplyWidth={false}
            isSideMenuOpen={props.showSideMenu}
          />
          {/* <IconButton
            icon={
              pathname === Pages.reports ? reportActiveIcon : reportInactiveIcon
            }
            margin="30px 0 0 0"
            onClick={() => {
              navigate(Pages.reports);
            }}
            width={40}
            height={40}
            padding="8px"
            background={pathname === Pages.reports ? "#D5D4E7" : ""}
            borderRadius={4}
            isSideMenu={pathname === Pages.reports}
            isApplyWidth={false}
            isSideMenuOpen={props.showSideMenu}
          /> */}
          <IconButton
            icon={
              pathname === Pages.trends ? trendsActiveIcon : trendsInactiveIcon
            }
            margin="30px 0 0 0"
            onClick={() => {
              navigate(Pages.trends);
              pathname !== Pages.trends && props.setShowSideMenuFirstTime(true);
            }}
            width={40}
            height={40}
            padding="8px"
            background={pathname === Pages.trends ? "#D5D4E7" : ""}
            borderRadius={4}
            isSideMenu={pathname === Pages.trends}
            onClickSideMenu={props.onSideMenuHide}
            isApplyWidth={false}
            isSideMenuOpen={props.showSideMenu}
          />
          <IconButton
            icon={
              pathname === Pages.sensors ? sensorActiveIcon : sensorInactiveIcon
            }
            margin="30px 0 0 0"
            onClick={() => {
              navigate(Pages.sensors);
            }}
            width={40}
            height={40}
            padding="8px"
            background={pathname === Pages.sensors ? "#D5D4E7" : ""}
            borderRadius={4}
            isApplyWidth={false}
          />
        </div>
        <div className={styles.bottom}>
          <IconButton
            icon={
              pathname === Pages.alerts ? alertsActiveIcon : alertsInactiveIcon
            }
            margin="30px 0 0 0"
            onClick={() => {
              navigate(Pages.alerts);
              pathname !== Pages.alerts && props.setShowSideMenuFirstTime(true);
            }}
            width={40}
            height={40}
            padding="8px"
            background={pathname === Pages.alerts ? "#D5D4E7" : ""}
            borderRadius={4}
            isSideMenu={pathname === Pages.alerts}
            onClickSideMenu={props.onSideMenuHide}
            isApplyWidth={false}
            isSideMenuOpen={props.showSideMenu}
          />
          <IconButton
            icon={
              pathname === Pages.settings
                ? settingActiveIcon
                : settingInactiveIcon
            }
            margin="30px 0 0 0"
            onClick={() => {
              navigate(Pages.settings);
              pathname !== Pages.settings &&
                props.setShowSideMenuFirstTime(true);
            }}
            width={40}
            height={40}
            padding="8px"
            background={pathname === Pages.settings ? "#D5D4E7" : ""}
            isSideMenu={pathname === Pages.settings}
            onClickSideMenu={props.onSideMenuHide}
            borderRadius={4}
            isApplyWidth={false}
            isSideMenuOpen={props.showSideMenu}
          />
          <IconButton
            icon={logoutIcon}
            margin="30px 0 30px 0"
            onClick={() => setShowLogoutModal(true)}
            width={40}
            height={40}
            padding="8px"
            borderRadius={4}
            isApplyWidth={false}
          />
          <Avatar
            image={
              "https://sm.ign.com/ign_nordic/cover/a/avatar-gen/avatar-generations_prsz.jpg"
            }
            onClick={() => {
              navigate(Pages.profile);
            }}
          />
        </div>
      </div>
      <AppModal
        show={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
        width={650}
      >
        <Container>
          <Row>
            <Col className={styles.txt1}>Logout</Col>
          </Row>
          <Row className="mt-4">
            <Col className={styles.txt2}>
              Are you sure you want to logout now?
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs="auto">
              <Button
                text="Logout"
                variant="Primary"
                borderRadius={24}
                type="button"
                padding={`12px 70px`}
                onClick={logout}
              />
            </Col>
            <Col xs="auto">
              <Button
                text="Cancel"
                onClick={() => setShowLogoutModal(false)}
                variant="Outline"
                borderRadius={24}
                type="button"
                padding="12px 70px"
              />
            </Col>
          </Row>
        </Container>
      </AppModal>
    </div>
  );
};

export default NavBar;
