export enum ReadingUnit {
  TON = "TON",
  LITER = "LITER",
  FAHRENHEIT = "FAHRENHEIT",
  GRAM = "GRAM",
  KILOGRAM = "KILOGRAM",
  KELVIN = "KELVIN",
  MILLILITRE = "MILLILITRE",
  CELSIUS = "CELSIUS",
  MILLIGRAM = "MILLIGRAM",
  GALLON = "GALLON",
}

export enum CommunicationMedium {
  EMAIL,
  WHATSAPP,
}

export enum ETemperature {
  CELSIUS,
  KELVIN,
  FAHRENHEIT,
}

export enum EWeight {
  MILLIGRAM,
  GRAM,
  KILOGRAM,
  TON,
  OUNCE,
  POUND,
}

export enum EPressure {
  PASCAL,
  BAR,
  PSI,
}

export enum EVolume {
  MILLILITRE,
  LITER,
  GALLON,
}

export enum EVoltage {
  MILLIVOLT,
  VOLT,
  KILOVOLT,
}

export enum EPower {
  MILLIWATT,
  WATT,
  KILOWATT,
  MEGAWATT,
  GIGAWATT,
}

export enum WidgetType {
  WIDGET_TYPE_01 = "WIDGET_TYPE_01",
  WIDGET_TYPE_02 = "WIDGET_TYPE_02",
  WIDGET_TYPE_03 = "WIDGET_TYPE_03",
}

export enum TimeRange {
  LAST_HOUR = "LAST_HOUR",
  LAST_30_MINUTES = "LAST_30_MINUTES",
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  LAST_7_DAYS = "LAST_7_DAYS",
  LAST_30_DAYS = "LAST_30_DAYS",
  LAST_3_MONTHS = "LAST_3_MONTHS",
  CUSTOM = "CUSTOM",
}

export enum GraphType {
  LINE = "LINE",
  BAR = "BAR",
  PIE = "PIE",
  AREA = "AREA",
  SCATTER = "SCATTER",
}

export enum UnitCategory {
  TEMPERATURE = "TEMPERATURE",
  WEIGHT = "WEIGHT",
  PRESSURE = "PRESSURE",
  VOLUME = "VOLUME",
  VOLTAGE = "VOLTAGE",
  POWER = "POWER",
}

export enum EConditionTypes {
  EQUALS = "EQUALS",
  NOT_EQUALS = "NOT_EQUALS",
  GREATER_THAN = "GREATER_THAN",
  LESS_THAN = "LESS_THAN",
  GREATER_THAN_OR_EQUAL = "GREATER_THAN_OR_EQUAL",
  LESS_THAN_OR_EQUAL = "LESS_THAN_OR_EQUAL",
}

export enum EUserRole {
  SUPERVISOR = "SUPERVISOR",
  OPERATOR = "OPERATOR",
  ENGINEER = "ENGINEER",
}

export enum EContactTypes {
  EMAIL = "EMAIL",
  MOBILE_NUMBER = "MOBILE_NUMBER",
}

export enum ActionType {
  ALERTS = "ALERTS",
}
