import { Col, Row } from "react-bootstrap";
import { Sensor } from "../../interfaces/modals";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";

interface CardProps {
  sensor: Sensor;
  onEdit: (sensor: Sensor) => void;
  onDelete: (sensorId: number) => void;
}

const SensorCard = (props: CardProps) => {
  const { sensor, onEdit, onDelete } = props;

  return (
    <Row className="align-items-center border border-gray-2 rounded p-3 m-2">
      <Col className="col-auto">
        <MaterialIcon icon="sensors" color="#2f2a89" />
      </Col>
      <Col className="text-dark font-size-14">{sensor.label}</Col>
      <Col className="text-light font-weight-500 font-size-14">
        {sensor.modelNumber}
      </Col>
      <Col className="col-auto">
        <MaterialIcon
          icon="edit_square"
          color="#69768b"
          className="cursor-pointer"
          onClick={() => {
            onEdit(sensor);
          }}
        />
      </Col>
      <Col className="col-auto">
        <MaterialIcon
          icon="delete"
          color="#bf4545"
          className="cursor-pointer"
          onClick={() => {
            onDelete(sensor.id);
          }}
        />
      </Col>
    </Row>
  );
};

export default SensorCard;
