import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
import styles from "./setting-side-menu.module.scss";

type MenuContextType = [boolean, React.Dispatch<React.SetStateAction<boolean>>];

interface IProps {
  setShowSideMenuState: React.Dispatch<React.SetStateAction<boolean>>;
  items: {
    id: number;
    itemName: string;
  }[];
  onClick: (id: number) => void;
  selectedId: number;
}

const SettingSideMenu = (props: IProps) => {
  const { setShowSideMenuState, items, onClick, selectedId } = props;

  const [showSideMenu, setShowSideMenu] =
    useOutletContext<MenuContextType>() ?? [false, () => {}];

  useEffect(() => {
    setShowSideMenuState(showSideMenu);
  }, [showSideMenu, setShowSideMenuState]);

  return (
    <Container
      fluid
      className={`${styles.settingSideMenuContainer} ${
        showSideMenu ? styles.show : styles.hide
      } me-0`}
    >
      <Row className={"align-items-center justify-content-between"}>
        <Col xs={"auto"}>
          <MaterialIcon
            icon="settings"
            color="#fff"
            fill
            size={34}
            className="bg-primary p-2 rounded-4"
          />
        </Col>
        <Col>
          <div className={styles.title}>Settings</div>
        </Col>
        <Col xs="auto">
          <MaterialIcon
            icon="close"
            className="cursor-pointer"
            size={25}
            onClick={() => {
              setShowSideMenu(false);
            }}
          />
        </Col>
      </Row>
      <Row className="mt-4 ps-2">
        {items.map((i) => (
          <Col
            key={i.id}
            xs={12}
            className={`${styles.item} ${
              selectedId === i.id && styles.selected
            }`}
            onClick={() => onClick(i.id)}
          >
            <Row>
              <Col
                className={`${styles.itemName} ${
                  selectedId === i.id && styles.selected
                }`}
              >
                {i.itemName}
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default SettingSideMenu;
