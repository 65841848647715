import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import { useViewTrendsQuery } from "../../../redux/api/trend-api/trend-api";
import { GraphType, TimeRange } from "../../enums";
import { Trend } from "../../interfaces/modals";
import Button from "../../ui-elements/button/button";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
import styles from "./trends-side-menu.module.scss";

type MenuContextType = [boolean, React.Dispatch<React.SetStateAction<boolean>>];

interface IProps {
  selectedTrend: Trend;
  newlyAddedTrend: Number;
  setShowSideMenuState: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTrend: React.Dispatch<React.SetStateAction<Trend>>;
  onDelete: (id: number) => void;
}

const defaultTrendValue: Trend = {
  id: 0,
  label: "",
  timeRange: TimeRange.LAST_30_MINUTES,
  additionalData: {},
  graphType: GraphType.AREA,
  frequencyInMinutes: 0,
  dataSources: [],
};

const TrendsSideMenu = (props: IProps) => {
  const {
    selectedTrend,
    newlyAddedTrend,
    setShowSideMenuState,
    setSelectedTrend,
    onDelete,
  } = props;

  const { data: trends } = useViewTrendsQuery({ page: 0, size: 500 });

  const [showSideMenu, setShowSideMenu] =
    useOutletContext<MenuContextType>() ?? [false, () => {}];

  useEffect(() => {
    setShowSideMenuState(showSideMenu);
  }, [showSideMenu]);

  useEffect(() => {
    if (trends && trends.trends.length > 0 && newlyAddedTrend) {
      setSelectedTrend(
        trends.trends.find((trend) => trend.id === newlyAddedTrend) || {
          ...defaultTrendValue,
        }
      );
    }
  }, [trends?.trends, newlyAddedTrend]);

  return (
    <div
      className={`bg-white p-4 rounded h-100 ${styles.container} ${
        showSideMenu ? styles.show : styles.hide
      }`}
    >
      <Row className={"align-items-center"}>
        <Col className="col-auto">
          <MaterialIcon
            icon="chart_data"
            color="#fff"
            fill
            size={34}
            className="bg-primary p-2 rounded-4"
          />
        </Col>
        <Col className="text-dark font-size-20">Trend Analysis</Col>
        <Col className="col-auto">
          <MaterialIcon
            icon="close"
            className="cursor-pointer"
            size={25}
            onClick={() => {
              setShowSideMenu(false);
            }}
          />
        </Col>
      </Row>
      <Row className="mt-4 ps-2">
        {trends &&
          trends.trends.map((trend) => {
            const isSelected = selectedTrend.id === trend.id;

            return (
              <Col
                key={trend.id}
                xs={12}
                className={`py-2 cursor-pointer ${
                  isSelected ? "bg-primary" : ""
                }`}
              >
                <Row>
                  <Col
                    className={`${isSelected ? "text-white" : "text-dark"}`}
                    onClick={() => {
                      setSelectedTrend(trend);
                    }}
                  >
                    {trend.label}
                  </Col>
                  <Col xs="auto">
                    <MaterialIcon
                      icon="delete"
                      color="#bf4545"
                      onClick={(event) => {
                        event.stopPropagation();
                        onDelete(trend.id);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            );
          })}
      </Row>
      <Row className="mt-4 justify-content-center">
        <Col xs="auto">
          <Button
            text="New Template"
            onClick={() => {
              setSelectedTrend(defaultTrendValue);
            }}
            variant="Primary"
            borderRadius={24}
            type="button"
            padding="12px 70px"
          />
        </Col>
      </Row>
    </div>
  );
};

export default TrendsSideMenu;
