import ICreateUserRequestDTO from "../../../shared/interfaces/dtos/request-dtos/create-user-request-dto";
import IUserIdentityResponseDTO from "../../../shared/interfaces/dtos/response-dtos/user-identity-response-dto";
import IViewAllUsersResponseDTO from "../../../shared/interfaces/dtos/response-dtos/view-all-users-response-dto";
import ViewContactsResponseDTO from "../../../shared/interfaces/dtos/response-dtos/view-contacts-response-dto";
import { rootApi } from "../api-manager";

export const userAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addUser: builder.mutation<IUserIdentityResponseDTO, ICreateUserRequestDTO>({
      query: (data: ICreateUserRequestDTO) => ({
        url: "/user",
        method: "POST",
        body: data,
      }),
    }),
    viewAllUsers: builder.query<
      IViewAllUsersResponseDTO,
      { page: number; size: number }
    >({
      query: (data) => ({
        url: `/user/all`,
        method: "GET",
        params: {
          page: data.page,
          size: data.size,
        },
      }),
    }),
    viewContacts: builder.query<
      ViewContactsResponseDTO,
      { page?: number; size?: number }
    >({
      query: ({ page, size }) => ({
        url: `/user/contact/all`,
        method: "GET",
        params: {
          page,
          size,
        },
      }),
    }),
  }),
});

export const {
  useAddUserMutation,
  useLazyViewAllUsersQuery,
  useViewContactsQuery,
} = userAPI;
