import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface WidgetDataState {
  [key: string]: any;
}

const initialState: WidgetDataState = {};

export const widgetDataSlice = createSlice({
  name: 'widgetData',
  initialState,
  reducers: {
    setWidgetData: (state, action: PayloadAction<{ widgetId: string, data: any }>) => {
      const { widgetId, data } = action.payload;
      state[widgetId] = data;
    },
    clearWidgetData: (state, action: PayloadAction<{ widgetId: string }>) => {
      const { widgetId } = action.payload;
      delete state[widgetId];
    },
  },
});

export const { setWidgetData, clearWidgetData } = widgetDataSlice.actions;

export default widgetDataSlice.reducer;
