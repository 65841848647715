import { useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { v4 as uuid } from "uuid";
import { GraphType } from "../../enums";
import SpinnerModal from "../../ui-elements/spinner/spinner";
import styles from "./chart.module.scss";

export interface ChartData {
  time: string;
  [key: string]: number | string;
}

export interface DataSet {
  sensorParamDefId: number;
  name: string;
  color: string;
  unit: string;
  dataKey: string;
}

interface IProps {
  data: ChartData[];
  dataSet: DataSet[];
  chartType: GraphType;
  isLoading: boolean;
  invisibleData: number[];
}

const Chart = (props: IProps) => {
  const { data, dataSet, chartType, isLoading, invisibleData } = props;
  const [filteredDataSet, setFilteredDataSet] = useState<DataSet[]>([]);

  useEffect(() => {
    setFilteredDataSet(
      dataSet.filter((ds) => !invisibleData.includes(ds.sensorParamDefId))
    );
  }, [invisibleData, dataSet]);

  return isLoading ? (
    <SpinnerModal show={isLoading} withOverlay={false} />
  ) : chartType === GraphType.AREA ? (
    <ResponsiveContainer width="100%" height={500}>
      <AreaChart
        data={data}
        margin={{ top: 20, right: 30, bottom: 0, left: 0 }}
      >
        <defs>
          {filteredDataSet.map((ds, index) => {
            return (
              <linearGradient
                key={uuid()}
                id={`colorUv${index}`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="5%" stopColor={ds.color} stopOpacity={0.8} />
                <stop offset="95%" stopColor="#2C2F48" stopOpacity={0} />
              </linearGradient>
            );
          })}
        </defs>
        <CartesianGrid strokeDasharray="3 3" stroke="#4A4A4A" />
        <XAxis dataKey="time" stroke="#383941" interval={0} />
        <YAxis stroke="#383941" />
        <Tooltip content={<CustomTooltip />} />
        {filteredDataSet.map((ds, index) => {
          return (
            <Area
              key={uuid()}
              type="linear"
              dataKey={ds.dataKey}
              stroke={ds.color}
              fillOpacity={1}
              fill={`url(#colorUv${index})`}
              unit={ds.unit}
              dot={{
                fill: "#ffffff",
                stroke: ds.color,
                strokeWidth: 2,
                r: 4,
              }}
              name={ds.name}
            />
          );
        })}
      </AreaChart>
    </ResponsiveContainer>
  ) : chartType === GraphType.BAR ? (
    <ResponsiveContainer width="100%" height={500}>
      <BarChart
        data={data}
        barCategoryGap={2}
        barGap={20}
        margin={{ top: 20, right: 30, bottom: 0, left: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke="#4A4A4A" />
        <XAxis dataKey="time" stroke="#ccc" interval={0} />
        <YAxis stroke="#ccc" />
        <Tooltip content={<CustomTooltip />} cursor={{ fill: "#00000044" }} />
        {filteredDataSet.map((ds) => {
          return (
            <Bar
              key={uuid()}
              dataKey={ds.dataKey}
              fill={ds.color}
              unit={ds.unit}
              name={ds.name}
            />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  ) : (
    <ResponsiveContainer width="100%" height={500}>
      <LineChart
        data={data}
        margin={{ top: 20, right: 30, bottom: 0, left: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke="#4A4A4A" />
        <XAxis dataKey="time" stroke="#383941" interval={0} />
        <YAxis stroke="#383941" />
        <Tooltip content={<CustomTooltip />} />
        {filteredDataSet.map((ds, index) => {
          return (
            <Line
              key={uuid()}
              type="monotone"
              dataKey={ds.dataKey}
              stroke={ds.color}
              activeDot={{ r: 8 }}
              unit={ds.unit}
              dot={{
                fill: "#ffffff",
                stroke: ds.color,
                strokeWidth: 2,
                r: 4,
              }}
              name={ds.name}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
}

const CustomTooltip = (customTooltipProps: CustomTooltipProps) => {
  const { active, payload, label } = customTooltipProps;
  if (active && payload?.length) {
    return (
      <div className={styles.tooltip}>
        <div>{`Time: ${label}`}</div>
        {payload.map((pl) => {
          return (
            <div className={"mt-1"} key={uuid()}>
              {`${pl.name}: ${pl.value} ${pl.unit}`}
            </div>
          );
        })}
        <div className={"mt-1"}>
          Critical - <span className={styles.critical}>No</span>
        </div>
      </div>
    );
  }
  return null;
};

export default Chart;
