import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  useAddDashboardMutation,
  useDeleteDashboardMutation,
  useUpdateDashboardMutation,
} from "../../../../redux/api/dashboard-api/dashboard-api";
import DeleteConfirmationModal from "../../../../shared/components/modals/delete-confirmation-modal/delete-confirmation-modal";
import { Dashboard } from "../../../../shared/interfaces/modals";
import Button from "../../../../shared/ui-elements/button/button";
import TextAria from "../../../../shared/ui-elements/text-aria/text-aria";
import TextField from "../../../../shared/ui-elements/text-field/text-field";
import { showFailureToast, showSuccessToast } from "../../../../shared/utils";
import AddUpdateWidget from "../add-update-widget/add-update-widget";
import styles from "./add-update-dashboard.module.scss";

type Inputs = {
  name: string;
  description: string;
};

interface IProps {
  selectedDashboard: Dashboard;
  onCancel: () => void;
  onClose: () => void;
}

const AddUpdateDashboard = (props: IProps) => {
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showAddUpdateWidget, setShowAddUpdateWidget] = useState(false);

  const [addDashboard, { isLoading: isLoadingAddDashboard }] =
    useAddDashboardMutation();
  const [updateDashboard, { isLoading: isLoadingUpdateDashboard }] =
    useUpdateDashboardMutation();
  const [deleteDashboard, { isLoading: isLoadingRemoveDashboard }] =
    useDeleteDashboardMutation();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  useEffect(() => {
    if (props.selectedDashboard) {
      setValue("name", props.selectedDashboard.label);
      setValue(
        "description",
        props.selectedDashboard.additionalFields?.description || ""
      );
    }
  }, [props.selectedDashboard, setValue]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (props.selectedDashboard.id) {
      onUpdate(data);
      return;
    } else {
      await addDashboard({
        label: data.name,
        additionalFields: {
          description: data.description,
        },
      })
        .unwrap()
        .then(() => {
          showSuccessToast("Dashboard saved successfully");
          props.onClose();
          reset();
        })
        .catch(() => {
          showFailureToast("Failed to save the dashboard");
          reset();
        });
    }
  };

  const onUpdate: SubmitHandler<Inputs> = async (data) => {
    if (props.selectedDashboard.id) {
      await updateDashboard({
        dashboardId: props.selectedDashboard.id,
        label: data.name,
        additionalFields: {
          description: data.description,
        },
      })
        .unwrap()
        .then(() => {
          showSuccessToast("Dashboard updated successfully");
          props.onClose();
        })
        .catch(() => {
          showFailureToast("Failed to update the dashboard");
        });
    }
  };

  const onDelete = async () => {
    if (props.selectedDashboard.id) {
      await deleteDashboard(props.selectedDashboard.id)
        .unwrap()
        .then(() => {
          showSuccessToast("Dashboard deleted successfully");
          setDeleteModal(false);
          props.onClose();
        })
        .catch(() => {
          showFailureToast("Failed to delete the dashboard");
        });
    }
  };

  return (
    <>
      <div className={`${styles.container} p-4`}>
        {showAddUpdateWidget ? (
          <AddUpdateWidget
            onCancel={() => setShowAddUpdateWidget(false)}
            selectedDashboardId={Number(props.selectedDashboard.id)}
            onClose={props.onClose}
          />
        ) : (
          <>
            <Row className="align-items-center">
              <Col className={styles.txt01}>
                {props.selectedDashboard.id
                  ? `Edit ${props.selectedDashboard.label}`
                  : `New`}
              </Col>
              {props.selectedDashboard.id && (
                <Col xs="auto">
                  <Button
                    type="button"
                    text="Add Widget"
                    variant={`Primary`}
                    borderRadius={24}
                    padding={`8px 40px`}
                    onClick={() => setShowAddUpdateWidget(true)}
                  />
                </Col>
              )}
            </Row>
            <Row>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row className="mt-4">
                  <Col xs={12}>
                    <TextField
                      name="name"
                      placeholder=""
                      label="Dashboard Name"
                      register={register("name", {
                        required: "Please enter a dashboard name",
                        minLength: {
                          value: 1,
                          message:
                            "This field must contain at least 1 characters",
                        },
                        maxLength: {
                          value: 50,
                          message:
                            "You have exceeded the maximum number of 50 characters in this field",
                        },
                      })}
                      errors={errors}
                    />
                  </Col>
                  <Col xs={12} className="mt-4">
                    <TextAria
                      label={"Description"}
                      inputProps={{
                        ...register("description", { required: true }),
                      }}
                    />
                    {errors.description && (
                      <div className="error">Please enter a description</div>
                    )}
                  </Col>
                </Row>
                <Row
                  className={`mt-4 row-cols-auto ${
                    props.selectedDashboard.id
                      ? `justify-content-between`
                      : `justify-content-end`
                  }`}
                >
                  {props.selectedDashboard.id && (
                    <Col>
                      <Button
                        type="button"
                        text="Delete"
                        variant={`Danger`}
                        borderRadius={24}
                        padding={`12px 70px`}
                        onClick={() => setDeleteModal(true)}
                      />
                    </Col>
                  )}
                  <Col>
                    <Row>
                      <Col>
                        <Button
                          text="Cancel"
                          onClick={props.onCancel}
                          variant={`Outline`}
                          borderRadius={24}
                          padding={`12px 70px`}
                          type="button"
                        />
                      </Col>
                      <Col>
                        <Button
                          type="submit"
                          text={props.selectedDashboard.id ? `Save` : `Create`}
                          variant={`Primary`}
                          borderRadius={24}
                          padding={`12px 70px`}
                          isLoading={
                            isLoadingAddDashboard || isLoadingUpdateDashboard
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </form>
            </Row>
          </>
        )}
      </div>
      <DeleteConfirmationModal
        show={showDeleteModal}
        onClose={() => setDeleteModal(false)}
        onConfirm={handleSubmit(onDelete)}
        title="Remove Dashboard"
        description="Are you sure you want to remove this dashboard?"
        isLoading={isLoadingRemoveDashboard}
      />
    </>
  );
};

export default AddUpdateDashboard;
