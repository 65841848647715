import { EColors } from "../enums/colors";

export function getWidgetTextColor(color: EColors): string {
  switch (color) {
    case EColors.BLUE:
      return "#0188CC";
    case EColors.GREEN:
      return "#4FB402";
    case EColors.PURPLE:
      return "#86069A";
    case EColors.RED:
      return "#CD000A";
    case EColors.YELLOW:
      return "#FFC70F";
    default:
      return "#383941";
  }
}
