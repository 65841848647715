import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "../shared/components/private-route/private-route";
import AuthLayout from "./auth-layout";
import Dashboards from "./dashboards/dashboards";
import Layout from "./layout";
import Login from "./login/login";
import NoPage from "./no-page/no-page";
import ManageNotifications from "./notifications/manage-notifications";
import ManageProfile from "./profile/page-profile";
import ManageReport from "./reports/manage-reports";
import AddUpdateSensor from "./sensors/add-update-sensor";
import ManageSensors from "./sensors/manage-sensors";
import AddUpdateUser from "./settings/components/add-update-user/add-update-user";
import PageSettings from "./settings/settings";
import ManageTrends from "./trends/manage-trends";
import AddUpdateTriggers from "./triggers/components/add-update-triggers/add-update-triggers";
import ManageTriggers from "./triggers/manage-triggers";

export enum Pages {
  login = "/login",
  sensors = "/sensors",
  addSensor = "/sensors/add-sensor",
  updateSensor = "/sensors/update-sensor",
  dashboards = "/dashboards",
  alerts = "/alerts",
  addAlert = "/alerts/add-alert",
  updateAlert = "/alerts/update-alert",
  trends = "/trends",
  reports = "/reports",
  profile = "/profile",
  settings = "/settings",
  addUser = "/sensors/add-user",
  notifications = "/notifications",
}

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AuthLayout />}>
          <Route index element={<Navigate to={Pages.login} />} />
          <Route
            path={Pages.login.toString().replace("/", "")}
            element={<Login />}
          />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route element={<Layout />}>
            <Route index element={<Navigate to={Pages.dashboards} />} />
            <Route
              path={Pages.alerts.toString().replace("/", "")}
              element={<ManageTriggers />}
            />
            <Route
              path={Pages.addAlert.toString().replace("/", "")}
              element={<AddUpdateTriggers />}
            />
            <Route
              path={Pages.updateAlert.toString().replace("/", "")}
              element={<AddUpdateTriggers />}
            />
            <Route
              path={Pages.reports.toString().replace("/", "")}
              element={<ManageReport />}
            />
            <Route
              path={Pages.sensors.toString().replace("/", "")}
              element={<ManageSensors />}
            />
            <Route
              path={Pages.addSensor.toString().replace("/", "")}
              element={<AddUpdateSensor />}
            />
            <Route
              path={Pages.updateSensor.toString().replace("/", "")}
              element={<AddUpdateSensor />}
            />
            <Route
              path={Pages.dashboards.toString().replace("/", "")}
              element={<Dashboards />}
            />
            <Route
              path={Pages.settings.toString().replace("/", "")}
              element={<PageSettings />}
            />
            <Route
              path={Pages.addUser.toString().replace("/", "")}
              element={<AddUpdateUser />}
            />
            <Route
              path={Pages.trends.toString().replace("/", "")}
              element={<ManageTrends />}
            />
            <Route
              path={Pages.profile.toString().replace("/", "")}
              element={<ManageProfile />}
            />
            <Route
              path={Pages.notifications.toString().replace("/", "")}
              element={<ManageNotifications />}
            />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
