import { Col, Container, Row } from "react-bootstrap";
import AppModal from "../../../ui-elements/app-modal/app-modal";
import Button from "../../../ui-elements/button/button";
import MaterialIcon from "../../../ui-elements/material-icon/material-icon";
import styles from "./delete-confirmation-modal.module.scss";

interface IProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
  title: string;
  description: string;
}

const DeleteConfirmationModal = (props: IProps) => {
  const { show, onClose, onConfirm, isLoading, title, description } = props;

  return (
    <AppModal
      show={show}
      onClose={() => {
        onClose();
      }}
      width={499}
    >
      <Container>
        <Row className="justify-content-center">
          <Col xs="auto" className={styles.title}>
            {title}
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col xs="auto">
            <MaterialIcon
              icon="question_mark"
              color="#fff"
              className="bg-danger p-2 rounded-circle"
              size={40}
            />
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col xs="auto" className={styles.subTitle}>
            {description}
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col xs="auto">
            <Button
              type="button"
              text="Cancel"
              variant="Primary"
              borderRadius={24}
              padding="12px 60px"
              onClick={onClose}
            />
          </Col>
          <Col xs="auto">
            <Button
              type="button"
              text="Yes"
              variant="Danger"
              borderRadius={24}
              padding="12px 60px"
              onClick={onConfirm}
              isLoading={isLoading}
            />
          </Col>
        </Row>
      </Container>
    </AppModal>
  );
};

export default DeleteConfirmationModal;
