import { ReadingUnit } from "../enums";

export function getReadingTypes(readingUnit: ReadingUnit | undefined): string {
  switch (readingUnit) {
    case ReadingUnit.TON:
      return "t";
    case ReadingUnit.LITER:
      return "L";
    case ReadingUnit.FAHRENHEIT:
      return "\u00B0F";
    case ReadingUnit.GRAM:
      return "g";
    case ReadingUnit.KILOGRAM:
      return "Kg";
    case ReadingUnit.KELVIN:
      return "K";
    case ReadingUnit.MILLILITRE:
      return "ml";
    case ReadingUnit.CELSIUS:
      return "\u00B0C";
    case ReadingUnit.MILLIGRAM:
      return "mg";
    case ReadingUnit.GALLON:
      return "gal";
    default:
      return "";
  }
}
