import AddSensorRequestDTO from "../../../shared/interfaces/dtos/request-dtos/add-sensor-request-dto";
import UpdateGeneralSensorResponseDTO from "../../../shared/interfaces/dtos/response-dtos/update-general-sensor-response-dto";
import ViewSensorsResponseDTO from "../../../shared/interfaces/dtos/response-dtos/view-sensors-response-dto";
import { rootApi } from "../api-manager";

// Define a service using a base URL and expected endpoints
export const sensorAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addSensor: builder.mutation({
      query: (sensor: AddSensorRequestDTO) => ({
        url: "/sensor/register",
        method: "POST",
        body: sensor,
      }),
      invalidatesTags: ["sensors"],
    }),
    updateSensor: builder.mutation<
      UpdateGeneralSensorResponseDTO,
      AddSensorRequestDTO
    >({
      query: (data: AddSensorRequestDTO) => ({
        url: `/sensor/${data.sensorId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["sensors"],
    }),
    removeSensor: builder.mutation<void, { sensorId: number }>({
      query({ sensorId }) {
        return {
          url: `sensor/${sensorId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["sensors"],
    }),
    viewSensors: builder.query<
      ViewSensorsResponseDTO,
      { page?: number; size?: number }
    >({
      query: ({ page, size }) => ({
        url: `/sensor/all`,
        method: "GET",
        params: {
          page,
          size,
        },
      }),
      providesTags: ["sensors"],
    }),
    checkSensorAvailability: builder.mutation<{ isAvailable: boolean }, string>(
      {
        query: (serialId) => ({
          url: `sensor/unregistered-sensor/availability/view?serialKey=${serialId}`,
          method: "GET",
        }),
      }
    ),
  }),
});

export const {
  useAddSensorMutation,
  useUpdateSensorMutation,
  useViewSensorsQuery,
  useCheckSensorAvailabilityMutation,
  useRemoveSensorMutation,
  useLazyViewSensorsQuery,
} = sensorAPI;
