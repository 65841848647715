import { Col, Container, Row } from "react-bootstrap";
import { ReadingUnit } from "../../enums";
import { EColors } from "../../enums/colors";
import { getReadingTypes } from "../../utils/getReadingTypes";
import { getWidgetTextColor } from "../../utils/getWidgetTextColor";
import MaterialIcon from "../material-icon/material-icon";
import styles from "./gauge.module.scss";

interface IProps {
  value: number;
  size?: "sm" | "lg";
  textColor?: EColors;
  unit?: ReadingUnit;
  minValue: number;
  maxValue: number;
}

const Gauge = (props: IProps) => {
  const { value, size, textColor, unit, minValue, maxValue } = props;

  return (
    <div className={`${styles.gauge} ${size === "sm" && styles.sm}`}>
      <div className={styles.gauge__body}>
        <div
          className={styles.gauge__fill}
          style={{
            transform: `rotate(${value / 200}turn`,
          }}
        ></div>
        <div className={styles.gauge__cover}>
          <Container fluid>
            <Row className={"align-items-center justify-content-center"}>
              <Col className="col-auto ps-0 pe-1">
                <MaterialIcon
                  icon="device_thermostat"
                  color="#CDDF60"
                  size={20}
                />
              </Col>
              <Col className="col-auto p-0">
                <label
                  className={styles.value}
                  style={{
                    color: `${getWidgetTextColor(textColor as EColors)}`,
                  }}
                >
                  {value}
                </label>
                <label className={`${styles.unit} ps-1`}>
                  {unit ? getReadingTypes(unit as ReadingUnit) || unit : "C"}
                </label>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Row className={styles.minValue}>
        <Col className="col-auto pe-0">
          <MaterialIcon
            icon="vertical_align_bottom"
            size={20}
            color="#383941"
          />
        </Col>
        <Col className="col-auto ps-1">
          {minValue} {unit ? getReadingTypes(unit as ReadingUnit) || unit : "C"}
        </Col>
      </Row>
      <Row className={styles.maxValue}>
        <Col className="col-auto pe-0">
          <MaterialIcon icon="vertical_align_top" size={20} color="#383941" />
        </Col>
        <Col className="col-auto ps-1">
          {maxValue} {unit ? getReadingTypes(unit as ReadingUnit) || unit : "C"}
        </Col>
      </Row>
      <div className={styles.markerMin}></div>
      <div className={styles.markerMax}></div>
    </div>
  );
};

export default Gauge;
