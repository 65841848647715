import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
interface IProps {
  sensorParamDefId: number;
  text: string;
  color: string;
  onRemove: () => void;
  invisibleChartData: number[];
  setInvisibleChartData: React.Dispatch<React.SetStateAction<number[]>>;
}

const Parameter = (props: IProps) => {
  const {
    text,
    color,
    sensorParamDefId,
    invisibleChartData,
    onRemove,
    setInvisibleChartData,
  } = props;

  return (
    <Row
      style={{ backgroundColor: color }}
      className="rounded-pill align-items-center flex-nowrap"
    >
      <Col className="col-auto text-white">{text}</Col>
      <Col className="col-auto px-0">
        <MaterialIcon
          icon={
            invisibleChartData.includes(sensorParamDefId)
              ? "visibility_off"
              : "visibility"
          }
          color="#fff"
          size={20}
          onClick={() => {
            setInvisibleChartData((ps) => {
              const isFound = ps.find((p) => p === sensorParamDefId);

              if (isFound) {
                return ps.filter((p) => p !== sensorParamDefId);
              } else {
                return [...ps, sensorParamDefId];
              }
            });
          }}
          className="cursor-pointer"
        />
      </Col>
      <Col className="col-auto">
        <MaterialIcon
          icon="close"
          color="#fff"
          size={20}
          onClick={onRemove}
          className="cursor-pointer"
        />
      </Col>
    </Row>
  );
};

export default Parameter;
