import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { EContactTypes } from "../../enums";
import { EContact } from "../../interfaces/modals";
import CheckBox from "../../ui-elements/check-box/check-box";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";

interface IProps {
  name: string;
  contacts: EContact[];
  selectedRecipients: number[];
  setSelectedRecipients: React.Dispatch<React.SetStateAction<number[]>>;
  selectedContactType: EContactTypes | undefined;
  search: string;
}

const Recipient = (props: IProps) => {
  const [showContacts, setShowContacts] = useState(false);

  const {
    name,
    contacts,
    selectedRecipients,
    selectedContactType,
    search,
    setSelectedRecipients,
  } = props;

  return (
    (name.toLowerCase().includes(search) ||
      contacts.some((contact) => contact.contact.includes(search))) && (
      <>
        <Row className="row-cols-auto align-items-center mt-3">
          <Col className="px-1">
            <MaterialIcon
              icon={showContacts ? "arrow_drop_down" : "arrow_right"}
              color={"#2f2a89"}
              size={32}
              onClick={() => setShowContacts((ps) => !ps)}
              className="cursor-pointer"
            />
          </Col>
          <Col className={`ps-0`}>{name}</Col>
          <Col className="ps-0">
            <CheckBox
              checked={contacts.every((contact) =>
                selectedRecipients.includes(contact.id)
              )}
              isWithColor={true}
              onChange={(checked) => {
                if (checked) {
                  setSelectedRecipients((ps) => {
                    let newContacts = contacts.map((c) => c.id);
                    newContacts = newContacts.filter((nc) => !ps.includes(nc));

                    return [...ps, ...newContacts];
                  });

                  return;
                }

                setSelectedRecipients((ps) =>
                  ps.filter(
                    (number) =>
                      contacts.find((c) => c.id === number) === undefined
                  )
                );
              }}
            />
          </Col>
        </Row>
        {showContacts &&
          contacts
            .filter((contact) =>
              !selectedContactType
                ? true
                : contact.contactType === selectedContactType
            )
            .filter((contact) => contact.contact.toLowerCase().includes(search))
            .map((contact) => {
              const isIncluded = selectedRecipients.find(
                (recipient) => recipient === contact.id
              )
                ? true
                : false;

              return (
                <Row
                  className="row-cols-auto align-items-center ms-4 ps-1 mt-1"
                  key={contact.id}
                >
                  <Col className={`py-1 px-2`}>
                    <Row className="align-items-center">
                      <Col xs="auto">
                        <MaterialIcon
                          icon={"contact_phone"}
                          color={"#69768B"}
                          size={20}
                        />
                      </Col>
                      <Col className={`ps-0`}>{contact.contact}</Col>
                    </Row>
                  </Col>
                  <Col className="ps-2">
                    <CheckBox
                      checked={isIncluded}
                      isWithColor={true}
                      onChange={(checked) => {
                        if (checked) {
                          setSelectedRecipients((ps) => {
                            return [...ps, contact.id];
                          });

                          return;
                        }

                        setSelectedRecipients((ps) =>
                          ps.filter((number) => number !== contact.id)
                        );
                      }}
                    />
                  </Col>
                </Row>
              );
            })}
      </>
    )
  );
};

export default Recipient;
