import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
import style from "./add-parameter-button.module.scss";
interface IProps {
  isDisable?: boolean;
  onAdd: () => void;
}

const AddParameterButton = (props: IProps) => {
  const { onAdd } = props;

  return (
    <Row
      className={`${style.addParameterButton} align-items-center rounded-pill cursor-pointer`}
      onClick={onAdd}
    >
      <Col className="col-auto">
        <MaterialIcon icon="add" color="#2f2a89" />
      </Col>
      <Col className="text-primary">Add Parameter</Col>
    </Row>
  );
};

export default AddParameterButton;
