import ViewTelemetryRecordsResponseDTO from "../../../shared/interfaces/dtos/response-dtos/view-telemetry-records-response-dto";
import { rootApi } from "../api-manager";

// Define a service using a base URL and expected endpoints
export const telemetryAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    viewTelemetryRecords: builder.query<ViewTelemetryRecordsResponseDTO, void>({
      query: () => ({
        url: `/telemetry/data-structures`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyViewTelemetryRecordsQuery } = telemetryAPI;
