export interface ITableRowProps {
  data: JSX.Element[];
  disable?: boolean;
}

const TableRow = (props: ITableRowProps) => {
  const { data } = props;
  return (
    <tr style={{ verticalAlign: "middle", borderColor: "#F4F4F4" }}>
      <>
        {data.map((item, index) => (
          <td
            key={index}
            className="text-light font-size-14 font-weight-400 text-bg-background-white py-2 px-4"
          >
            {item}
          </td>
        ))}
      </>
    </tr>
  );
};

export default TableRow;
