import SaveTrendRequestDTO from "../../../shared/interfaces/dtos/request-dtos/save-trend-request-dto";
import TelemetryViewRequestDTO from "../../../shared/interfaces/dtos/request-dtos/telemetry-view-request-dto";
import TelemetryViewResponseDTO from "../../../shared/interfaces/dtos/response-dtos/telemetry-view-response-dto";
import ViewTrendResponseDTO from "../../../shared/interfaces/dtos/response-dtos/view-trend-response-dto";
import { rootApi } from "../api-manager";

// Define a service using a base URL and expected endpoints
export const trendAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    viewTrends: builder.query<
      ViewTrendResponseDTO,
      { page?: number; size?: number }
    >({
      query: ({ size, page }) => ({
        url: "/trend/all",
        params: {
          page,
          size,
        },
      }),
      providesTags: ["trends"],
    }),
    saveTrend: builder.mutation<{ id: number }, SaveTrendRequestDTO>({
      query: (trend: SaveTrendRequestDTO) => ({
        url: "/trend",
        method: "POST",
        body: trend,
      }),
      invalidatesTags: ["trends"],
    }),
    updateTrend: builder.mutation<{ id: number }, SaveTrendRequestDTO>({
      query: (trend: SaveTrendRequestDTO) => ({
        url: `/trend/${trend.id}`,
        method: "PUT",
        body: trend,
      }),
      invalidatesTags: ["trends"],
    }),
    deleteTrend: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/trend/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["trends"],
    }),
    telemetryView: builder.query<
      TelemetryViewResponseDTO,
      TelemetryViewRequestDTO
    >({
      query: (data: TelemetryViewRequestDTO) => ({
        url: `/trend/sensor-param-definition/${data.sensorParamDefId}/telemetry/view`,
        method: "GET",
        params: {
          startTime: data.startTime,
          endTime: data.endTime,
          frequencyInMinutes: data.frequencyInMinutes,
        },
      }),
    }),
  }),
});

export const {
  useViewTrendsQuery,
  useLazyTelemetryViewQuery,
  useSaveTrendMutation,
  useUpdateTrendMutation,
  useDeleteTrendMutation,
} = trendAPI;
