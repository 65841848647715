import { Col, Container, Row } from "react-bootstrap";
import oilTankImg from "../../../assets/images/oil_barrel.svg";
import { WidgetType } from "../../../enums";
import { EColors } from "../../../enums/colors";
import IconButton from "../../../ui-elements/icon-button/icon-button";
import MaterialIcon from "../../../ui-elements/material-icon/material-icon";
import OilTank from "../../../ui-elements/oil-tank/oil-tank";
import { getWidgetTextColor } from "../../../utils/getWidgetTextColor";
import styles from "../counter-widget/counter-widget.module.scss";

interface IProps {
  type: WidgetType;
  textColor: EColors;
}

const LevelWidget = (props: IProps) => {
  return (
    <Container
      fluid
      className={`${styles.widgetContainer} ${
        props.type === WidgetType.WIDGET_TYPE_03 && styles.selectedWidget
      } p-4`}
    >
      <Row className="justify-content-between">
        <Col className={styles.title}>Oil Level</Col>
      </Row>
      <Row className="mt-4">
        <Col xs={"auto"}>
          <OilTank value={50} size="sm" />
        </Col>
        <Col className="ps-0">
          <Row>
            <Col className="ps-1">
              <IconButton
                padding={"8px"}
                icon={oilTankImg}
                width={40}
                height={40}
              />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col
              xs="auto"
              className={styles.value}
              style={{
                color: `${getWidgetTextColor(props.textColor as EColors)}`,
              }}
            >
              50
            </Col>
            <Col xs="auto" className={`ps-0 mt-3 ${styles.unit}`}>
              Liters
            </Col>
          </Row>
          <Row className="row-cols-auto">
            <Col>
              <Row className="align-items-center">
                <Col xs={"auto"} className="pe-0">
                  <MaterialIcon
                    icon="vertical_align_top"
                    size={16}
                    color="#383941"
                  />
                </Col>
                <Col xs={"auto"} className={`ps-1 ${styles.exchangeValue}`}>
                  0
                </Col>
              </Row>
            </Col>
            <Col className="ps-4">
              <Row className="align-items-center">
                <Col xs="auto" className="pe-0">
                  <MaterialIcon
                    icon="vertical_align_bottom"
                    size={16}
                    color="#383941"
                  />
                </Col>
                <Col xs={"auto"} className={`ps-1 ${styles.exchangeValue}`}>
                  100
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default LevelWidget;
