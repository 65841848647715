import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface IProps {
  data: { name: string; value: number }[];
}

const AreaChartComponent = (props: IProps) => {
  const { data } = props;
  return (
    <AreaChart
      width={450}
      height={300}
      data={data}
      margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
    >
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop stopColor="#0E9CFF" stopOpacity={0.3} />
        </linearGradient>
      </defs>
      <XAxis dataKey="name" dy={8} interval={0} />
      <YAxis dx={-8} />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip />
      <Area
        type="linear"
        dataKey="value"
        stroke="#0E9CFF"
        fillOpacity={1}
        fill="url(#colorUv)"
        dot={{
          stroke: "#0E9CFF",
          fill: "#ffffff",
          strokeWidth: 2,
          r: 4,
        }}
      />
    </AreaChart>
  );
};

export default AreaChartComponent;
