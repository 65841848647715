import { Table } from "react-bootstrap";
import MaterialIcon from "../../ui-elements/material-icon/material-icon";
import TableRow, {
  ITableRowProps,
} from "../../ui-elements/table-row/table-row";

interface IHeader {
  id: number;
  columnName: string | JSX.Element;
  isSort?: boolean;
  sortFunction?: () => void;
  sort?: boolean;
  isTextEnd?: boolean;
}

interface IProps {
  headers: IHeader[];
  data: ITableRowProps[];
}

const DataTable = (props: IProps) => {
  const { headers, data } = props;
  return (
    <Table responsive>
      <thead>
        <tr>
          {headers.map((header) => {
            return (
              <th
                key={header.id}
                className={`text-dark font-size-14 font-weight-400 text-bg-background-white py-3 px-4 ${
                  header.isTextEnd && `text-end`
                }`}
                style={{
                  verticalAlign: "middle",
                  borderRadius: "8px",
                  borderColor: "#F4F4F4",
                }}
              >
                {header.isSort ? (
                  <div
                    className={`d-flex align-items-center cursor-pointer gap-1 `}
                    onClick={header.sortFunction && header.sortFunction}
                  >
                    {header.columnName}
                    <div
                      className="dArrow"
                      style={
                        !header.sort
                          ? { transform: "rotate(0deg)" }
                          : { transform: "rotate(180deg)" }
                      }
                    >
                      <MaterialIcon
                        icon="arrow_downward"
                        color="#383941"
                        size={14}
                      />
                    </div>
                  </div>
                ) : (
                  header.columnName
                )}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data && data.map((item, index) => <TableRow key={index} {...item} />)}
      </tbody>
    </Table>
  );
};

export default DataTable;
