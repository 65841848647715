import { Col, Container, Row } from "react-bootstrap";
import styles from "./progress-bar.module.scss";

interface IProps {
  minValue: number;
  maxValue: number;
  optimalValue: number;
  sensorReadings: number;
}

const ProgressBar = (props: IProps) => {
  return (
    <Container fluid className={styles.progressBarContainer}>
      <Row>
        <Col className={`p-0 ${styles.text}`}>{props.minValue}</Col>
        <Col className={`p-0 text-center ${styles.text}`}>
          {props.optimalValue}
        </Col>
        <Col className={`p-0 text-end ${styles.text}`}>{props.maxValue}</Col>
      </Row>
      <Row className="mt-1">
        <Col className={"p-0"}>
          <div className={styles.progressBar}>
            <div
              className={styles.progress}
              style={{ width: `${props.sensorReadings}%` }}
            ></div>
          </div>
        </Col>
      </Row>
      <Row className={"mt-1"}>
        <Col className={`p-0 ${styles.text}`}>Min</Col>
        <Col className={`p-0 text-center ${styles.text}`}>Optimal</Col>
        <Col className={`p-0 text-end ${styles.text}`}>Max</Col>
      </Row>
    </Container>
  );
};

export default ProgressBar;
