import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { rootApi } from "../api/api-manager";
import listenerMiddleware from "../api/websocket-middleware";
import authReducer from "../slices/auth-slice";
import { rtkQueryErrorLogger } from "../api/rtkQueryErrorLogger";
import widgetDataReducer from "../slices/widgetDataSlice";

export const reduxStore = configureStore({
  reducer: {
    auth: authReducer,
    [rootApi.reducerPath]: rootApi.reducer,
    widgetData: widgetDataReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(listenerMiddleware.middleware)
      .concat(rootApi.middleware, rtkQueryErrorLogger),
  devTools: process.env.NODE_ENV !== "development",
});

export type RootState = ReturnType<typeof reduxStore.getState>;
export type AppDispatch = typeof reduxStore.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
